//for the table as a whole. Z-index set so that it does not cover the drop-down menu
.details {
  padding: 0rem 1rem 1rem 1rem;
  display: flex;
  align-items: flex-start;
  // flex-direction: row;
  z-index: 5;
  // width: 100%;
}

// to style font of all span elements
span {
  font-family: inherit;
  font-size: 1.3rem;
}

input {
  font-family: inherit;
  font-size: 1.3rem;
  border: 1px solid #d5d9d9;
}

//to style the column-hide drop-down menu awesomefont icons
.fa-check-square,
.fa-square {
  color: $color-grey-dark-2;
  margin-right: 0.8rem;
}

//to style the row expansion details plus/minus awesomefont icons
.fa-plus,
.fa-minus {
  color: $color-grey-dark-2;
  font-size: 62.5%;
}

.fa-times-circle {
  color: #e61515;
  font-size: 110%;
}
.fa-plus-circle {
  color: #18cc27;
  font-size: 110%;
  cursor: pointer;
}

.fa-caret-down {
  color: $color-grey-dark-2;
  margin-left: 0.8rem;
}

.fa-file-invoice-dollar,
.edit-item {
  color: $color-primary;
  font-size: 120%;
}

//to hide the expanded rows details initially
.hidden {
  display: none;
}

//to set footer elements to be flex and wrap to another line when view-port is small
.tabulator-footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}
//to change font size for footer pagination
.tabulator,
.tabulator-page,
.tabulator-page-size,
.tabulator-menu-item {
  color: inherit;
  font-family: inherit;
  font-size: 13px;
}

//to position pagination elements at the footer of table. Display block so that it will be responsive when viewport width is smaller
.tabulator-paginator {
  display: block;
  position: relative;
  align-items: center;
  margin: 0.5rem 0;
}

//row background color upon selection
.tabulator-row.tabulator-selected {
  background-color: $color-light-blue !important;
}
