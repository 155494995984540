//To configure the CSS of the pop-up screen for creation of new records

.add-package-window,
.add-customer-window,
.add-treatment-window,
.add-item-window,
.billdoc-window,
.add-cartcheckout-window,
.add-catalogmaster-window,
.add-registry_selectfield-window,
.add-registry_selectlist-window,
.add-registry_keyobject-window,
.add-registry_reftype-window {
  max-height: 95%; //need to set height for overflow to work, but we use max height so that the height still automatically fit the content instead of a fixed height
  overflow-y: auto;
  // overflow-x: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  border-radius: 3px;
  padding: 5rem 5rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 30;
  transition: all 0.5s;

  @media only screen and (max-width: $bp-large) {
    width: 73%;
  }

  @media only screen and (max-width: $bp-medium) {
    padding: 3rem 3rem;
    width: 60%;
  }

  @media only screen and (max-width: $bp-small) {
    width: 90%;
  }
}

.btn--close-modal-package,
.btn--close-modal-customer,
.btn--close-modal-treatment,
.btn--close-modal-item,
.btn--close-modal-billdoc,
.btn--close-modal-content,
.btn--close-modal-camera,
.btn--close-modal-cart-checkout,
.btn--close-modal-catalogmaster,
.btn--close-modal-registry_selectfield,
.btn--close-modal-registry_selectlist,
.btn--close-modal-registry_keyobject,
.btn--close-modal-registry_reftype {
  font-family: inherit;
  color: inherit;
  position: absolute;
  top: 0.5rem;
  right: 3.8rem;
  font-size: 3.5rem;
  cursor: pointer;
  border: none;
  background: none;
}

.overlay-package,
.overlay-customer,
.overlay-treatment,
.overlay-item,
.overlay-billdoc,
.overlay-cartcheckout,
.overlay-catalogmaster,
.overlay-registry_selectfield,
.overlay-registry_selectlist,
.overlay-registry_keyobject,
.overlay-registry_reftype {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5px);
  z-index: 20;
  transition: all 0.5s;
}

.upload-cartcheckout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 4rem;
  align-items: start;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    gap: 2rem 6rem;
  }

  &__column {
    display: block;
    align-items: center;
    gap: 1.5rem;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr 1.8fr;
      gap: 1rem;
    }

    & label {
      font-size: 1.3rem;
      font-weight: 600;
      color: inherit;
      // vertical-align: middle;
      display: block;
    }

    & button {
      grid-column: 1 / span 2;
      justify-self: center;
      margin-top: 1rem;
    }
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    grid-column: 1/-1;
  }

  &__subheading {
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  &__btn {
    grid-column: 1 / -1; // to remove the columns so that the button can be centered
    justify-self: center;
    margin-left: 3rem;
  }
}

.add-cartcheckout-form {
  color: inherit;
  font-size: 1.3rem;
  font-family: inherit;
  padding: 0.8rem 1rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  transition: all 0.2s;
  vertical-align: middle;
  margin-right: 1%;

  &::placeholder {
    color: $color-grey-light-3;
  }

  &:focus {
    outline: none;
    border: 1px solid $color-blue;
    background-color: $color-grey-light-1;
  }
}

.upload-package,
.upload-customer,
.upload-treatment,
.upload-item,
.upload-catalogmaster,
.upload-registry_selectfield,
.upload-registry_selectlist,
.upload-registry_keyobject,
.upload-registry_reftype {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 4rem;
  align-items: start;

  @media only screen and (max-width: $bp-medium) {
    grid-template-columns: 1fr;
    gap: 2rem 6rem;
  }

  &__column {
    display: block;
    align-items: center;
    gap: 1.5rem;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: $bp-medium) {
      grid-template-columns: 1fr 1.8fr;
      gap: 1rem;
    }

    & label {
      font-size: 1.3rem;
      font-weight: 600;
      color: inherit;
      // vertical-align: middle;
      display: block;
    }

    & input,
    & select,
    & select > option {
      color: inherit;
      font-size: 1.3rem;
      font-family: inherit;
      padding: 0.8rem 1rem;
      border: 1px solid #ddd;
      border-radius: 0.5rem;
      transition: all 0.2s;
      vertical-align: middle;
      margin-right: 1%;

      &::placeholder {
        color: $color-grey-light-3;
      }

      &:focus {
        outline: none;
        border: 1px solid $color-blue;
        background-color: $color-grey-light-1;
      }
    }

    & button {
      grid-column: 1 / span 2;
      justify-self: center;
      margin-top: 1rem;
    }
  }

  &__heading {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
    grid-column: 1/-1;
  }

  &__btn {
    grid-column: 1 / -1; // to remove the columns so that the button can be centered
    justify-self: center;
  }
}

.upload-bill-package__btn,
.upload-addcart-package__btn,
.upload-cartcheckoutmanual__btn {
  justify-self: center;
  margin-left: 2rem;
}

.upload-package__btns,
.upload-cartcheckout__btns {
  grid-column: 1 / -1; // to remove the columns so that the button can be centered
  display: flex;
  justify-content: center;
}

.popup-content__btns {
  display: flex;
  justify-content: center;
}

.required::after {
  color: #e32;
  content: ' *';
  display: inline;
}

.upload-row-padding {
  margin-bottom: 1rem;
}

.upload-section-row-padding {
  margin-bottom: 2rem;
}

.upload-row-inline {
  display: flex;
  flex-direction: row;
}

// for text in add form that needs to be vertically align to the bottom
.add-form-span {
  vertical-align: bottom;
}

.upload_row_separator_line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.18);
  padding-bottom: 16px;
}

.add-package-form-customer-matchlist,
.add-catalogmaster-form-item-matchlist,
.add-registry_selectlist-form-keyfield-matchlist,
.add-registry_reftype-form-keyobject-matchlist {
  background-color: #fff;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  position: absolute;
  // padding-right: 0.5rem;
  width: 17.3rem;
  // top: 14rem;
  // left: 6rem;
  z-index: 40;

  // @media only screen and (max-width: $bp-medium) {
  //   width: 18rem;
  //   top: 12rem;
  //   left: 3rem;
  // }

  // @media only screen and (max-width: $bp-small) {
  //   left: 3rem;
  // }

  // @media only screen and (max-width: $bp-smallest) {
  //   left: 3rem;
  // }

  &__details {
    padding: 0.5rem 0 0.5rem 0.4rem;
    position: relative;
    display: block;
    z-index: 40;
  }

  &__details:hover {
    cursor: default;
    background: $color-grey-light-2;
  }

  &__details-selected {
    background: $color-grey-light-2;
  }
}

.add-customer-form-gender {
  vertical-align: middle;
  margin-right: 4%;
}

.add-package-form-treatment-matchlist {
  background-color: #fff;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  position: absolute;
  width: 17.3rem;
  z-index: 40;

  &__details {
    padding: 0.5rem 0 0.5rem 0.4rem;
    position: relative;
    display: block;
  }

  &__details:hover {
    cursor: default;
    background: $color-grey-light-2;
  }

  &__details-selected {
    background: $color-grey-light-2;
  }
}

.overlay-error-message,
.overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5px);
  z-index: 50;
}

.popup-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50rem;
  background-color: white;
  border-radius: 9px;
  padding: 5rem 6rem;
  box-shadow: 0 4rem 6rem rgba(0, 0, 0, 0.25);
  z-index: 60;
  transition: all 0.5s;
  text-align: center;

  svg {
    height: 3rem;
    width: 3rem;
    fill: $color-primary;
    margin-right: 0.7rem;
    transform: translateY(-1px);
    display: inline;
    vertical-align: middle;
  }

  @media only screen and (max-width: $bp-medium) {
    width: auto;
  }
}

.messagebtn {
  // text-align: center; //only for display inline element
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  width: 10rem;
  color: inherit;
  font-family: inherit;
  font-size: 1.3rem;
  margin: 2.5rem auto 0 auto; //use auto (left/right) to center a block element
}

.messagebtn:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

.popup-message-text {
  font-family: inherit;
  font-size: 1.6rem;
  color: inherit;
  font-weight: 700;
  display: inline;
  vertical-align: middle;

  @media only screen and (max-width: $bp-medium) {
    font-size: 1.3rem;
  }
}

// Drag and Drop for Image

.add-item-drag-area,
.add-treatment-drag-area {
  width: 90%;
  border: 3px dashed #e0eafc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 10px auto;
  font-family: inherit;
  color: inherit;
}

.add-item-drag-edit,
.add-treatment-drag-edit {
  font-size: 1.4rem;
  color: $color-primary;
  cursor: pointer;
  margin-left: 0.5rem;
}
.add-item-drag-area .add-item-drag-icon,
.add-treatment-drag-area .add-treatment-drag-icon {
  font-size: 5rem;
  color: $color-primary;
}
.add-item-drag-area .add-item-drag-header,
.add-treatment-drag-area .add-treatment-drag-header {
  font-size: 2rem;
  font-weight: 500;
}
.add-item-drag-area .add-item-drag-support,
.add-treatment-drag-area .add-treatment-drag-support {
  font-size: 12px;
  margin: 10px 0 15px 0;
}
.add-item-drag-area .add-item-drag-browsebutton,
.add-treatment-drag-area .add-treatment-drag-browsebutton {
  font-size: 1.8rem;
  font-weight: 500;
  color: $color-primary;
  cursor: pointer;
}
.add-item-drag-area.active,
.add-treatment-drag-area.active {
  border: 2px solid $color-primary;
}
.add-item-drag-area img,
.add-treatment-drag-area img {
  width: 100%;
  height: 736px;
  object-fit: cover;
}
.add-item-image-header,
.add-treatment-image-header {
  display: flex;
  align-items: center;
}

.add-item-image-additional-content {
  height: 736px;
  width: 100%;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.add-item-image-additional-content::-webkit-scrollbar {
  width: 2px;
}

.add-item-image-additional-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.add-item-image-additional-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.add-item-image-additional {
  transition: 0.4s;
  aspect-ratio: 1/1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
