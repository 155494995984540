//to position buttons and count records at the footer of table. Display block so that it will be responsive when viewport width is smaller
.actionbtns,
.chartbtns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 1.5rem;
  // top: 6rem;
  // position: fixed;
}

.registrybtns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-left: 1.5rem;
  // height: 4rem;
}

.registrybtn {
  background-color: #fff;
  border: none;
  // border-radius: 8px;
  // box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  user-select: none;
  width: 10rem;
  display: block;
  // margin-left: 0.4rem;
  color: inherit;
  font-family: inherit;
  font-size: 1.4rem;
  transition: all 0.3s;
}

.registrybtn:hover {
  color: $color-primary;
  font-weight: bold;
  transform: translateY(-2px);
  cursor: pointer;
}

.registrybtn.active {
  border-bottom: 2px solid #007bff;
  font-weight: bold;
  color: $color-primary;
}

//to style the action buttons
.actionbtn {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  user-select: none;
  width: 10rem;
  display: block;
  margin-left: 0.4rem;
  color: inherit;
  font-family: inherit;
  font-size: 1.3rem;
}

.item-size {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 1rem;
  bottom: 6rem;
  opacity: 0;
  transition: opacity 1000ms;
  visibility: hidden;
  // z-index: 5;
}

.popup-item-size {
  display: grid;
  grid-template-columns: 50% 50%;
  // flex-flow: row wrap;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
}

.catsizebtn {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  // border-radius: 8px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  user-select: none;
  width: 10.1rem;
  display: block;
  // margin-left: 0.4rem;
  position: relative;
  color: inherit;
  font-family: inherit;
  font-size: 1.3rem;
}

.popupcatsizebtn {
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 4px;
  box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  user-select: none;
  width: 23rem;
  display: block;
  position: relative;
  color: inherit;
  font-family: inherit;
  font-size: 1.3rem;

  @media only screen and (max-width: $bp-smallest) {
    width: auto;
  }
}

// To style the popup size buttons when it is clicked
.popupcatsizebtnselected {
  background-color: #000;
  color: #fff;
}

.chartbtn {
  margin-left: 2rem;
  border-radius: 20px;
}

//To style the action buttons when it is disabled
.actionbtn:disabled,
.catsizebtn:disabled,
.popupcatsizebtn:disabled,
.popup-addtocart:disabled,
.cart-camera:disabled {
  opacity: 0.5;
  pointer-events: none;
}

//To style the action buttons when it is enabled and on hover
.actionbtn:not(.disabled):hover,
.catsizebtn:not(.disabled):hover,
.popupcatsizebtn:not(.disabled):hover {
  cursor: pointer;
  background: rgba(185, 181, 181, 0.9);
  color: #fff;
}

//to style the dropdown action buttons
.dropdownbtn {
  background-color: #fff;
  border: 0px solid #fff;
  box-sizing: border-box;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  user-select: none;
  width: 10rem;
  display: block;
  font-family: inherit;
  font-size: 1.3rem;
}

//Apply top margin to the first element of the Export button dropdown menu to push down the dropdown box for alignment so as to offset the negative top margin of actionbtn__dropdown
// .export--xlsx {
//   margin-top: 0.6rem;
// }

//Hide the Export button dropdown menu initially
.actionbtn__dropdown {
  display: none;
  position: absolute;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.4);
  z-index: 25;
  margin-left: 1rem;
  margin-top: -0.7rem; //Apply negative top margin to move the box up to cover the gap between Export button and dropdown menu box so that dropdown menu will not disappear
}

// .dropdown {
//   display: inline-block;
//   position: relative;
// }

// Display the Export button dropdown menu on hover. Element must contain both classes ('dropdown' AND 'dropdown--enabled')
// .dropdown.dropdown--enabled:hover .actionbtn__dropdown {
//   display: block;
// }

.dropdownbtn:disabled {
  opacity: 0.5;
}

.dropdownbtn:not(.disabled):hover {
  cursor: pointer;
  background: $color-grey-light-4;
  color: #fff;
}

.doc__btn {
  background: none;
  border: none;
  cursor: pointer;
  padding-right: 1.5rem;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  position: relative;
  left: 45%;
  svg {
    height: 4rem;
    width: 4rem;
    fill: $color-primary;
    transform: translateY(-1px);
  }

  &:hover {
    transform: translateY(-2px);
  }
}

.doc__btn--bill_whatsapp {
  svg {
    fill: #25d366;
  }
}
