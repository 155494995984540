.login {
  background-color: #15172b;
  border-radius: 20px;
  box-sizing: border-box;
  height: 500px;
  padding: 20px;
  width: 320px;
}

.login__title {
  color: #eee;
  font-family: sans-serif;
  font-size: 3.6rem;
  font-weight: 600;
  margin-top: 3rem;
  text-align: center;
}

.login__subtitle {
  color: #eee;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.login__message {
  color: #dc2f55;
  font-family: sans-serif;
  font-size: 1.2rem;
  margin-top: 3rem;
  text-align: center;
}

.login__input-container {
  height: 50px;
  position: relative;
  width: 100%;
}

.login__ic1 {
  margin-top: 4rem;
}

.login__ic2 {
  margin-top: 3rem;
}

.login__input {
  background-color: #303245;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  font-size: 1.6rem;
  height: 100%;
  outline: 0;
  padding: 4px 20px 0;
  width: 100%;
}

.login__cut {
  background-color: #15172b;
  border-radius: 10px;
  height: 20px;
  left: 20px;
  position: absolute;
  top: -20px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
}

.login__input:focus ~ .login__cut,
.login__input:not(:placeholder-shown) ~ .login__cut {
  transform: translateY(8px);
}

.login__placeholder {
  color: #65657b;
  font-family: sans-serif;
  font-size: 1.6rem;
  left: 20px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
}

.login__input:focus ~ .login__placeholder,
.login__input:not(:placeholder-shown) ~ .login__placeholder {
  transform: translateY(-30px) translateX(10px) scale(0.75);
}

.login__input:not(:placeholder-shown) ~ .login__placeholder {
  color: #808097;
}

.login__input:focus ~ .login__placeholder {
  color: #dc2f55;
}

.fa-eye-slash,
.fa-eye {
  color: #65657b;
  position: absolute;
  top: 20px;
  left: 250px;
  cursor: pointer;
}

.login__submit {
  background-color: #08d;
  border-radius: 12px;
  border: 0;
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  font-size: 18px;
  height: 50px;
  margin-top: 38px;
  text-align: center;
  width: 100%;
}

.login__submit:hover {
  color: #b1b0f3;
  background-color: #07c;
}

.login__submit:active {
  background-color: #06b;
}

.login__spinner {
  svg {
    height: 3rem;
    width: 3rem;
    fill: #08d;
    animation: rotate 2s infinite linear;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
