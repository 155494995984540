.tabulator {
  text-align: left;
  background-color: #fff;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

.tabulator[tabulator-layout="fitDataFill"] .tabulator-tableholder .tabulator-table {
  min-width: 100%;
}

.tabulator[tabulator-layout="fitDataTable"] {
  display: inline-block;
}

.tabulator.tabulator-block-select {
  user-select: none;
}

.tabulator .tabulator-header {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  border-bottom: 1px solid #999;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header.tabulator-header-hidden {
  display: none;
}

.tabulator .tabulator-header .tabulator-col {
  box-sizing: border-box;
  text-align: left;
  vertical-align: bottom;
  background: #f9fafb;
  border-right: 1px solid #ddd;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col.tabulator-moving {
  pointer-events: none;
  background: #dae1e7;
  border: 1px solid #999;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  box-sizing: border-box;
  padding: 4px;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button {
  padding: 0 8px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-header-menu-button:hover {
  cursor: pointer;
  opacity: .6;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title-holder {
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-title-editor {
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border: 1px solid #999;
  padding: 1px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title .tabulator-header-menu-button + .tabulator-title-editor {
  width: calc(100% - 22px);
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 4px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  width: 0;
  height: 0;
  border-bottom: 6px solid #bbb;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  border-top: 1px solid #ddd;
  margin-right: -1px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.tabulator .tabulator-header .tabulator-col:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter {
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  margin-top: 2px;
  position: relative;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter textarea {
  height: auto !important;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter svg {
  margin-top: 3px;
}

.tabulator .tabulator-header .tabulator-col .tabulator-header-filter input::-ms-clear {
  width: 0;
  height: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-right: 25px;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable:hover {
  cursor: pointer;
  background-color: #dae1e7;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter {
  color: #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="none"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #bbb;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter {
  color: #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="asc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  border-top: none;
  border-bottom: 6px solid #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter {
  color: #666;
}

.tabulator .tabulator-header .tabulator-col.tabulator-sortable[aria-sort="desc"] .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  color: #666;
  border-top: 6px solid #666;
  border-bottom: none;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical .tabulator-col-content .tabulator-col-title {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  justify-content: center;
  align-items: center;
  display: flex;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-col-vertical-flip .tabulator-col-title {
  transform: rotate(180deg);
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-title {
  padding-top: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable.tabulator-col-vertical-flip .tabulator-col-title {
  padding-bottom: 20px;
  padding-right: 0;
}

.tabulator .tabulator-header .tabulator-col.tabulator-col-vertical.tabulator-sortable .tabulator-col-sorter {
  justify-content: center;
  inset: 4px 0 auto;
}

.tabulator .tabulator-header .tabulator-frozen {
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #ddd;
}

.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #ddd;
}

.tabulator .tabulator-header .tabulator-calcs-holder {
  box-sizing: border-box;
  min-width: 600%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  background: #fff !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row {
  background: #fff !important;
}

.tabulator .tabulator-header .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder {
  min-width: 600%;
}

.tabulator .tabulator-header .tabulator-frozen-rows-holder:empty {
  display: none;
}

.tabulator .tabulator-tableholder {
  width: 100%;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: auto;
}

.tabulator .tabulator-tableholder:focus {
  outline: none;
}

.tabulator .tabulator-tableholder .tabulator-placeholder {
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  display: flex;
}

.tabulator .tabulator-tableholder .tabulator-placeholder[tabulator-render-mode="virtual"] {
  min-height: 100%;
  min-width: 100%;
}

.tabulator .tabulator-tableholder .tabulator-placeholder span {
  color: #ccc;
  margin: 0 auto;
  padding: 10px;
  font-size: 20px;
  font-weight: 700;
  display: inline-block;
}

.tabulator .tabulator-tableholder .tabulator-table {
  white-space: nowrap;
  color: #333;
  background-color: #fff;
  display: inline-block;
  position: relative;
  overflow: visible;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs {
  font-weight: 700;
  background: #e2e2e2 !important;
}

.tabulator .tabulator-footer {
  color: #555;
  white-space: nowrap;
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  background-color: #fff;
  border-top: 1px solid #999;
  padding: 5px 10px;
  font-weight: 700;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  box-sizing: border-box;
  width: calc(100% + 20px);
  text-align: left;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: -5px -10px 5px;
  overflow: hidden;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row .tabulator-col-resize-handle {
  display: none;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  margin-bottom: -5px;
}

.tabulator .tabulator-footer .tabulator-paginator {
  color: #555;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
}

.tabulator .tabulator-footer .tabulator-page-size {
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 0 5px;
  padding: 2px 5px;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-pages {
  margin: 0 7px;
}

.tabulator .tabulator-footer .tabulator-page {
  background: #fff3;
  border: 1px solid #aaa;
  border-radius: 3px;
  margin: 0 2px;
  padding: 2px 5px;
  display: inline-block;
}

.tabulator .tabulator-footer .tabulator-page.active {
  color: #d00;
}

.tabulator .tabulator-footer .tabulator-page:disabled {
  opacity: .5;
}

.tabulator .tabulator-footer .tabulator-page:not(.disabled):hover {
  cursor: pointer;
  color: #fff;
  background: #0003;
}

.tabulator .tabulator-col-resize-handle {
  width: 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.tabulator .tabulator-col-resize-handle.prev {
  left: 0;
  right: auto;
}

.tabulator .tabulator-col-resize-handle:hover {
  cursor: ew-resize;
}

.tabulator .tabulator-loader {
  z-index: 100;
  height: 100%;
  width: 100%;
  text-align: center;
  background: #0006;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  background: #fff;
  border-radius: 10px;
  margin: 0 auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-loading {
  color: #000;
  border: 4px solid #333;
}

.tabulator .tabulator-loader .tabulator-loader-msg.tabulator-error {
  color: #590000;
  border: 4px solid #d00;
}

.tabulator-row {
  box-sizing: border-box;
  min-height: 22px;
  background-color: #fff;
  position: relative;
}

.tabulator-row.tabulator-row-even {
  background-color: #efefef;
}

.tabulator-row.tabulator-selectable:hover {
  cursor: pointer;
  background-color: #bbb;
}

.tabulator-row.tabulator-selected {
  background-color: #9abcea;
}

.tabulator-row.tabulator-selected:hover {
  background-color: #769bcc;
}

.tabulator-row.tabulator-row-moving {
  background: #fff;
  border: 1px solid #000;
}

.tabulator-row.tabulator-moving {
  pointer-events: none;
  z-index: 15;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: absolute;
}

.tabulator-row .tabulator-row-resize-handle {
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.tabulator-row .tabulator-row-resize-handle.prev {
  top: 0;
  bottom: auto;
}

.tabulator-row .tabulator-row-resize-handle:hover {
  cursor: ns-resize;
}

.tabulator-row .tabulator-frozen {
  background-color: inherit;
  z-index: 10;
  display: inline-block;
  position: absolute;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-left {
  border-right: 2px solid #ddd;
}

.tabulator-row .tabulator-frozen.tabulator-frozen-right {
  border-left: 2px solid #ddd;
}

.tabulator-row .tabulator-responsive-collapse {
  box-sizing: border-box;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px;
}

.tabulator-row .tabulator-responsive-collapse:empty {
  display: none;
}

.tabulator-row .tabulator-responsive-collapse table {
  font-size: 14px;
}

.tabulator-row .tabulator-responsive-collapse table tr td {
  position: relative;
}

.tabulator-row .tabulator-responsive-collapse table tr td:first-of-type {
  padding-right: 10px;
}

.tabulator-row .tabulator-cell {
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-right: 1px solid #ddd;
  padding: 4px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  border: 1px solid #1d68cd;
  outline: none;
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input, .tabulator-row .tabulator-cell.tabulator-editing select {
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail {
  border: 1px solid #db2828;
}

.tabulator-row .tabulator-cell.tabulator-validation-fail input, .tabulator-row .tabulator-cell.tabulator-validation-fail select {
  color: #db2828;
  background: none;
  border: 1px;
}

.tabulator-row .tabulator-cell:first-child .tabulator-col-resize-handle.prev {
  display: none;
}

.tabulator-row .tabulator-cell.tabulator-row-handle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box {
  width: 80%;
}

.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
  width: 100%;
  height: 3px;
  background: #666;
  margin-top: 2px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  vertical-align: middle;
  height: 9px;
  width: 7px;
  border-bottom: 2px solid #ddd;
  border-left: 2px solid #ddd;
  border-bottom-left-radius: 1px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control {
  vertical-align: middle;
  height: 11px;
  width: 11px;
  background: #0000001a;
  border: 1px solid #333;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  height: 7px;
  width: 1px;
  background: none;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #333;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  height: 7px;
  width: 1px;
  background: #333;
  display: inline-block;
  position: relative;
}

.tabulator-row .tabulator-cell .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #333;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  height: 15px;
  width: 15px;
  background: #666;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  font-weight: 700;
  display: inline-flex;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle:hover {
  opacity: .7;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-close {
  display: initial;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle.open .tabulator-responsive-collapse-toggle-open, .tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle .tabulator-responsive-collapse-toggle-close {
  display: none;
}

.tabulator-row .tabulator-cell .tabulator-traffic-light {
  height: 14px;
  width: 14px;
  border-radius: 14px;
  display: inline-block;
}

.tabulator-row.tabulator-group {
  box-sizing: border-box;
  min-width: 100%;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  border-right: 1px solid #ddd;
  padding: 5px 5px 5px 10px;
  font-weight: 700;
}

.tabulator-row.tabulator-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-row.tabulator-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #666;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-row.tabulator-group.tabulator-group-level-1 {
  padding-left: 30px;
}

.tabulator-row.tabulator-group.tabulator-group-level-2 {
  padding-left: 50px;
}

.tabulator-row.tabulator-group.tabulator-group-level-3 {
  padding-left: 70px;
}

.tabulator-row.tabulator-group.tabulator-group-level-4 {
  padding-left: 90px;
}

.tabulator-row.tabulator-group.tabulator-group-level-5 {
  padding-left: 110px;
}

.tabulator-row.tabulator-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-row.tabulator-group .tabulator-arrow {
  width: 0;
  height: 0;
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #666;
  border-right: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-row.tabulator-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-menu {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  border: 1px solid #ddd;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
  box-shadow: 0 0 5px #0003;
}

.tabulator-menu .tabulator-menu-item {
  box-sizing: border-box;
  user-select: none;
  padding: 5px 10px;
  position: relative;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-disabled {
  opacity: .5;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  cursor: pointer;
  background: #efefef;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu {
  padding-right: 25px;
}

.tabulator-menu .tabulator-menu-item.tabulator-menu-item-submenu:after {
  height: 7px;
  width: 7px;
  content: "";
  vertical-align: top;
  border: 1px solid #ddd;
  border-width: 1px 1px 0 0;
  display: inline-block;
  position: absolute;
  top: calc(5px + .4em);
  right: 10px;
  transform: rotate(45deg);
}

.tabulator-menu .tabulator-menu-separator {
  border-top: 1px solid #ddd;
}

.tabulator-edit-select-list {
  box-sizing: border-box;
  max-height: 200px;
  -webkit-overflow-scrolling: touch;
  z-index: 10000;
  border: 1px solid #ddd;
  font-size: 14px;
  display: inline-block;
  position: absolute;
  overflow-y: auto;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item {
  color: #333;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.focused {
  outline: 1px solid #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  cursor: pointer;
  background: #1d68cd;
}

.tabulator-edit-select-list .tabulator-edit-select-list-notice {
  color: #333;
  text-align: center;
  padding: 4px;
}

.tabulator-edit-select-list .tabulator-edit-select-list-group {
  color: #333;
  border-bottom: 1px solid #ddd;
  padding: 6px 4px 4px;
  font-weight: 700;
}

.tabulator.tabulator-ltr {
  direction: ltr;
}

.tabulator.tabulator-rtl {
  text-align: initial;
  direction: rtl;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col {
  text-align: initial;
  border-left: 1px solid #ddd;
  border-right: initial;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-col-group .tabulator-col-group-cols {
  margin-left: -1px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col.tabulator-sortable .tabulator-col-title {
  padding-left: 25px;
  padding-right: 0;
}

.tabulator.tabulator-rtl .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  left: 8px;
  right: auto;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell {
  border-right: initial;
  border-left: 1px solid #ddd;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-branch {
  border-left: initial;
  border-right: 2px solid #ddd;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 1px;
  margin-left: 5px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-row .tabulator-cell .tabulator-data-tree-control {
  margin-left: 5px;
  margin-right: 0;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle {
  position: absolute;
  left: 0;
  right: auto;
}

.tabulator.tabulator-rtl .tabulator-col-resize-handle.prev {
  left: auto;
  right: 0;
}

.tabulator-print-fullscreen {
  z-index: 10000;
  position: absolute;
  inset: 0;
}

body.tabulator-print-fullscreen-hide > :not(.tabulator-print-fullscreen) {
  display: none !important;
}

.tabulator-print-table {
  border-collapse: collapse;
}

.tabulator-print-table .tabulator-data-tree-branch {
  vertical-align: middle;
  height: 9px;
  width: 7px;
  border-bottom: 2px solid #ddd;
  border-left: 2px solid #ddd;
  border-bottom-left-radius: 1px;
  margin-top: -9px;
  margin-right: 5px;
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group {
  box-sizing: border-box;
  min-width: 100%;
  background: #ccc;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  border-right: 1px solid #ddd;
  padding: 5px 5px 5px 10px;
  font-weight: 700;
}

.tabulator-print-table .tabulator-print-table-group:hover {
  cursor: pointer;
  background-color: #0000001a;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-visible .tabulator-arrow {
  border: 6px solid #0000;
  border-top-color: #666;
  border-bottom: 0;
  margin-right: 10px;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-1 td {
  padding-left: 30px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-2 td {
  padding-left: 50px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-3 td {
  padding-left: 70px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-4 td {
  padding-left: 90px !important;
}

.tabulator-print-table .tabulator-print-table-group.tabulator-group-level-5 td {
  padding-left: 110px !important;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-group-toggle {
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group .tabulator-arrow {
  width: 0;
  height: 0;
  vertical-align: middle;
  border: 6px solid #0000;
  border-left-color: #666;
  border-right: 0;
  margin-right: 16px;
  display: inline-block;
}

.tabulator-print-table .tabulator-print-table-group span {
  color: #d00;
  margin-left: 10px;
}

.tabulator-print-table .tabulator-data-tree-control {
  vertical-align: middle;
  height: 11px;
  width: 11px;
  background: #0000001a;
  border: 1px solid #333;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  display: inline-flex;
  overflow: hidden;
}

.tabulator-print-table .tabulator-data-tree-control:hover {
  cursor: pointer;
  background: #0003;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse {
  height: 7px;
  width: 1px;
  background: none;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-collapse:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #333;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand {
  height: 7px;
  width: 1px;
  background: #333;
  display: inline-block;
  position: relative;
}

.tabulator-print-table .tabulator-data-tree-control .tabulator-data-tree-control-expand:after {
  content: "";
  height: 1px;
  width: 7px;
  background: #333;
  position: absolute;
  top: 3px;
  left: -3px;
}

.tabulator {
  width: 100%;
  box-shadow: none;
  color: #000000de;
  border: 1px solid #22242626;
  border-radius: .28571rem;
  margin: 1em 0;
}

.tabulator .tabulator-header {
  box-shadow: none;
  color: #000000de;
  font-style: none;
  text-transform: none;
  border-bottom: 1px solid #2224261a;
  font-weight: 700;
}

.tabulator .tabulator-header, .tabulator .tabulator-header .tabulator-col {
  background-color: #f9fafb;
  border-right: none;
}

.tabulator .tabulator-header .tabulator-col .tabulator-col-content {
  padding: .92857em .78571em;
}

.tabulator .tabulator-tableholder .tabulator-table {
  background-color: #0000;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs {
  background: #f2f2f2 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-top {
  border-bottom: 2px solid #ddd;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.tabulator-calcs.tabulator-calcs-bottom {
  border-top: 2px solid #ddd;
}

.tabulator .tabulator-footer {
  box-shadow: none;
  text-align: right;
  color: #000000de;
  text-transform: none;
  background: #f9fafb;
  border-top: 1px solid #22242626;
  padding: .78571em;
  font-style: normal;
  font-weight: 400;
}

.tabulator .tabulator-footer .tabulator-calcs-holder {
  margin: -.78571em -.78571em .78571em;
  background: #fff !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder .tabulator-row {
  background: #fff !important;
}

.tabulator .tabulator-footer .tabulator-calcs-holder:only-child {
  border-bottom: none;
  margin-bottom: -.78571em;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.positive, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.positive {
  box-shadow: inset 0 0 #a3c293;
  color: #21ba45 !important;
  background: #fcfff5 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.positive:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.positive:hover {
  color: #13ae38 !important;
  background: #f7ffe6 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.negative, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.negative {
  box-shadow: inset 0 0 #e0b4b4;
  color: #db2828 !important;
  background: #fff6f6 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.negative:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.negative:hover {
  color: #d41616 !important;
  background: #ffe7e7 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.error, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.error {
  box-shadow: inset 0 0 #e0b4b4;
  color: #db2828 !important;
  background: #fff6f6 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.error:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.error:hover {
  color: #d12323 !important;
  background: #ffe7e7 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.warning, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row.warning {
  box-shadow: inset 0 0 #c9ba9b;
  color: #f2c037 !important;
  background: #fffaf3 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.warning:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row.warning:hover {
  color: #f1bb29 !important;
  background: #fff4e4 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.active, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.active {
  box-shadow: inset 0 0 #000000de;
  color: #000000de !important;
  background: #e0e0e0 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.active:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.active:hover {
  color: #13ae38 !important;
  background: #f7ffe6 !important;
}

.tabulator .tabulator-tableholder .tabulator-table .tabulator-row.active, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row.disabled:hover, .tabulator .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell.active {
  pointer-events: none;
  color: #0003;
}

.tabulator.inverted {
  color: #ffffffe6;
  background: #333;
  border: none;
}

.tabulator.inverted .tabulator-header {
  color: #ffffffe6;
  background-color: #00000026;
}

.tabulator.inverted .tabulator-header, .tabulator.inverted .tabulator-header .tabulator-col {
  border-color: #ffffff1a !important;
}

.tabulator.inverted .tabulator-tableholder .tabulator-table .tabulator-row {
  color: #ffffffe6;
  border: none;
}

.tabulator.inverted .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  border-color: #ffffff1a !important;
}

.tabulator.inverted .tabulator-footer {
  background: #fff;
}

.tabulator.striped .tabulator-row:nth-child(2n) {
  background-color: #0000000d;
}

.tabulator.celled {
  border: 1px solid #22242626;
}

.tabulator.celled .tabulator-header .tabulator-col, .tabulator.celled .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: 1px solid #2224261a;
}

.tabulator[class*="single line"] .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  border-right: none;
}

.tabulator.red {
  border-top: .2em solid #db2828;
}

.tabulator.inverted.red {
  color: #fff !important;
  background-color: #db2828 !important;
}

.tabulator.orange {
  border-top: .2em solid #f2711c;
}

.tabulator.inverted.orange {
  color: #fff !important;
  background-color: #f2711c !important;
}

.tabulator.yellow {
  border-top: .2em solid #fbbd08;
}

.tabulator.inverted.yellow {
  color: #fff !important;
  background-color: #fbbd08 !important;
}

.tabulator.olive {
  border-top: .2em solid #b5cc18;
}

.tabulator.inverted.olive {
  color: #fff !important;
  background-color: #b5cc18 !important;
}

.tabulator.green {
  border-top: .2em solid #21ba45;
}

.tabulator.inverted.green {
  color: #fff !important;
  background-color: #21ba45 !important;
}

.tabulator.teal {
  border-top: .2em solid #00b5ad;
}

.tabulator.inverted.teal {
  color: #fff !important;
  background-color: #00b5ad !important;
}

.tabulator.blue {
  border-top: .2em solid #2185d0;
}

.tabulator.inverted.blue {
  color: #fff !important;
  background-color: #2185d0 !important;
}

.tabulator.violet {
  border-top: .2em solid #6435c9;
}

.tabulator.inverted.violet {
  color: #fff !important;
  background-color: #6435c9 !important;
}

.tabulator.purple {
  border-top: .2em solid #a333c8;
}

.tabulator.inverted.purple {
  color: #fff !important;
  background-color: #a333c8 !important;
}

.tabulator.pink {
  border-top: .2em solid #e03997;
}

.tabulator.inverted.pink {
  color: #fff !important;
  background-color: #e03997 !important;
}

.tabulator.brown {
  border-top: .2em solid #a5673f;
}

.tabulator.inverted.brown {
  color: #fff !important;
  background-color: #a5673f !important;
}

.tabulator.grey {
  border-top: .2em solid #767676;
}

.tabulator.inverted.grey {
  color: #fff !important;
  background-color: #767676 !important;
}

.tabulator.black {
  border-top: .2em solid #1b1c1d;
}

.tabulator.inverted.black {
  color: #fff !important;
  background-color: #1b1c1d !important;
}

.tabulator.padded .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 1em;
}

.tabulator.padded .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 20px;
}

.tabulator.padded .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 1em;
}

.tabulator.padded.very .tabulator-header .tabulator-col .tabulator-col-content {
  padding: 1.5em;
}

.tabulator.padded.very .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 26px;
}

.tabulator.padded.very .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  padding: 1.5em;
}

.tabulator.compact .tabulator-header .tabulator-col .tabulator-col-content {
  padding: .5em .7em;
}

.tabulator.compact .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 12px;
}

.tabulator.compact .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  padding: .5em .7em;
}

.tabulator.compact.very .tabulator-header .tabulator-col .tabulator-col-content {
  padding: .4em .6em;
}

.tabulator.compact.very .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
  top: 10px;
}

.tabulator.compact.very .tabulator-tableholder .tabulator-table .tabulator-row .tabulator-cell {
  padding: .4em .6em;
}

.tabulator-row {
  border-bottom: 1px solid #2224261a;
}

.tabulator-row.tabulator-row-even {
  background-color: #0000;
}

.tabulator-row.tabulator-selectable:hover {
  box-shadow: inset 0 0 #000000de;
  color: #000000de !important;
  background: #e0e0e0 !important;
}

.tabulator-row.tabulator-selected {
  background-color: #9abcea !important;
}

.tabulator-row.tabulator-selected:hover {
  cursor: pointer;
  background-color: #769bcc !important;
}

.tabulator-row.tabulator-moving {
  pointer-events: none !important;
}

.tabulator-row .tabulator-cell {
  vertical-align: middle;
  border-right: none;
  padding: .78571em;
}

.tabulator-row .tabulator-cell:last-of-type {
  border-right: none;
}

.tabulator-row .tabulator-cell .tabulator-responsive-collapse-toggle {
  color: #fff;
}

.tabulator-row.tabulator-group {
  background: #fafafa;
}

.tabulator-row.tabulator-group span {
  color: #666;
}

.tabulator-menu {
  background: #fff;
}

.tabulator-menu .tabulator-menu-item:not(.tabulator-menu-item-disabled):hover {
  background: #f9fafb;
}

.tabulator-edit-select-list {
  background: #fff;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active {
  color: #fff;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item.active.focused {
  outline: 1px solid #ffffff80;
}

.tabulator-edit-select-list .tabulator-edit-select-list-item:hover {
  color: #fff;
}

.tabulator-edit-select-list .tabulator-edit-select-list-notice {
  color: inherit;
}

.tabulator-print-table .tabulator-print-table-group {
  background: #fafafa;
}

.tabulator-print-table .tabulator-print-table-group span {
  color: #666;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
}

.fa, .fa-brands, .fa-classic, .fa-regular, .fa-sharp, .fa-solid, .fab, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fa-classic, .fa-regular, .fa-solid, .far, .fas {
  font-family: "Font Awesome 6 Free";
}

.fa-brands, .fab {
  font-family: "Font Awesome 6 Brands";
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-radius: var(--fa-border-radius, .1em);
  border: var(--fa-border-width, .08em) var(--fa-border-style, solid) var(--fa-border-color, #eee);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade, .fa-fade {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-shake, .fa-spin {
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
}

.fa-spin {
  animation-name: fa-spin;
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-beat-fade, .fa-bounce, .fa-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    -webkit-transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  to {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, to {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, to {
    transform: rotate(0deg);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none));
}

.fa-stack {
  height: 2em;
  vertical-align: middle;
  width: 2.5em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  z-index: var(--fa-stack-z-index, auto);
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-chevron-circle-right:before, .fa-circle-chevron-right:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-trash-alt:before, .fa-trash-can:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-times:before, .fa-user-xmark:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-comment-alt:before, .fa-message:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-compress-alt:before, .fa-down-left-and-up-right-to-center:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-alt:before, .fa-file-lines:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-calendar-alt:before, .fa-calendar-days:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball-ball:before, .fa-volleyball:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-sort-desc:before, .fa-sort-down:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-heart-music-camera-bolt:before, .fa-icons:before {
  content: "";
}

.fa-microphone-alt-slash:before, .fa-microphone-lines-slash:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-football-ball:before, .fa-football:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angle-double-down:before, .fa-angles-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-burn:before, .fa-fire-flame-simple:before {
  content: "";
}

.fa-male:before, .fa-person:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-pastafarianism:before, .fa-spaghetti-monster-flying:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-pencil-alt:before, .fa-pencil:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-file-clipboard:before, .fa-paste:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-truck-loading:before, .fa-truck-ramp-box:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch-broom-ball:before, .fa-quidditch:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-archive:before, .fa-box-archive:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-alt:before, .fa-tablet-screen-button:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-people-arrows-left-right:before, .fa-people-arrows:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-caret-square-right:before, .fa-square-caret-right:before {
  content: "";
}

.fa-cut:before, .fa-scissors:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-table:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-digital-tachograph:before, .fa-tachograph-digital:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-mail-reply:before, .fa-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-minus-square:before, .fa-square-minus:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-caret-square-down:before, .fa-square-caret-down:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
  content: "";
}

.fa-heart-broken:before, .fa-heart-crack:before {
  content: "";
}

.fa-external-link-square-alt:before, .fa-square-up-right:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-chevron-circle-down:before, .fa-circle-chevron-down:before {
  content: "";
}

.fa-unlock-alt:before, .fa-unlock-keyhole:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-alt:before, .fa-headphones-simple:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-down:before, .fa-volume-low:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-alt:before, .fa-wheat-awn:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-check-square:before, .fa-square-check:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-header:before, .fa-heading:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-list-squares:before, .fa-list:before {
  content: "";
}

.fa-phone-square-alt:before, .fa-square-phone-flip:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-dizzy:before, .fa-face-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-futbol-ball:before, .fa-futbol:before, .fa-soccer-ball:before {
  content: "";
}

.fa-paint-brush:before, .fa-paintbrush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-edit:before, .fa-pen-to-square:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-share-alt:before, .fa-share-nodes:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-hand-paper:before, .fa-hand:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-deaf:before, .fa-deafness:before, .fa-ear-deaf:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-rss-square:before, .fa-square-rss:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before, .fa-staff-snake:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-ambulance:before, .fa-truck-medical:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-joint:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-g:before {
  content: "G";
}

.fa-notes-medical:before {
  content: "";
}

.fa-temperature-2:before, .fa-temperature-half:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-bolt:before, .fa-poo-storm:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-bookmark:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-folder-blank:before, .fa-folder:before {
  content: "";
}

.fa-file-medical-alt:before, .fa-file-waveform:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-dashboard:before, .fa-gauge-med:before, .fa-gauge:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-magic-wand-sparkles:before, .fa-wand-magic-sparkles:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pen-alt:before, .fa-pen-clip:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-shuttle-van:before, .fa-van-shuttle:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-caret-square-left:before, .fa-square-caret-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-area-chart:before, .fa-chart-area:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-air-freshener:before, .fa-spray-can-sparkles:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-expand-arrows-alt:before, .fa-maximize:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-random:before, .fa-shuffle:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-spider:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-user-friends:before, .fa-user-group:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-arrow-circle-up:before, .fa-circle-arrow-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-microphone-alt:before, .fa-microphone-lines:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi-3:before, .fa-wifi-strong:before, .fa-wifi:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-user-edit:before, .fa-user-pen:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-style:before, .fa-border-top-left:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-jedi:before {
  content: "";
}

.fa-poll:before, .fa-square-poll-vertical:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-battery-car:before, .fa-car-battery:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-h:before, .fa-mars-stroke-right:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-caret-square-up:before, .fa-square-caret-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-bar-chart:before, .fa-chart-bar:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-plus-square:before, .fa-square-plus:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-glass-martini-alt:before, .fa-martini-glass:before {
  content: "";
}

.fa-rotate-back:before, .fa-rotate-backward:before, .fa-rotate-left:before, .fa-undo-alt:before {
  content: "";
}

.fa-columns:before, .fa-table-columns:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly-box:before, .fa-dolly:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-compress-arrows-alt:before, .fa-minimize:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angle-double-right:before, .fa-angles-right:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-eur:before, .fa-euro-sign:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-check-circle:before, .fa-circle-check:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-chevron-circle-up:before, .fa-circle-chevron-up:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-gbp:before, .fa-pound-sign:before, .fa-sterling-sign:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-mail-reply-all:before, .fa-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-arrow-circle-down:before, .fa-circle-arrow-down:before {
  content: "";
}

.fa-arrow-right-to-file:before, .fa-file-import:before {
  content: "";
}

.fa-external-link-square:before, .fa-square-arrow-up-right:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-0:before, .fa-temperature-empty:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-balance-scale-right:before, .fa-scale-unbalanced-flip:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload-alt:before, .fa-cloud-upload:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-arrows-alt-h:before, .fa-left-right:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-arrow-circle-left:before, .fa-circle-arrow-left:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-remove-format:before, .fa-text-slash:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-arrows-h:before, .fa-arrows-left-right:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download-alt:before, .fa-cloud-download:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-blackboard:before, .fa-chalkboard:before {
  content: "";
}

.fa-user-alt-slash:before, .fa-user-large-slash:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-handshake-alt-slash:before, .fa-handshake-simple-slash:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-shield-alt:before, .fa-shield-halved:before {
  content: "";
}

.fa-atlas:before, .fa-book-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-archive:before, .fa-file-zipper:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-glass-martini:before, .fa-martini-glass-empty:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-feather-alt:before, .fa-feather-pointed:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-snowflake:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-ad:before, .fa-rectangle-ad:before {
  content: "";
}

.fa-arrow-circle-right:before, .fa-circle-arrow-right:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-1-2:before, .fa-list-numeric:before, .fa-list-ol:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-money-check-alt:before, .fa-money-check-dollar:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-arrows-alt:before, .fa-up-down-left-right:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-alt:before, .fa-star-half-stroke:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-glass-whiskey:before, .fa-whiskey-glass:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-krw:before, .fa-won-sign:before, .fa-won:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-cab:before, .fa-taxi:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-hard-hat:before, .fa-hat-hard:before, .fa-helmet-safety:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-arrow-alt-circle-right:before, .fa-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-map-signs:before, .fa-signs-post:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-tarp:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-home-user:before, .fa-house-user:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-cocktail:before, .fa-martini-glass-citrus:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-apple-alt:before, .fa-apple-whole:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-1:before, .fa-temperature-quarter:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-poll-h:before, .fa-square-poll-horizontal:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball-ball:before, .fa-basketball:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-arrow-alt-circle-up:before, .fa-circle-up:before {
  content: "";
}

.fa-mobile-alt:before, .fa-mobile-screen-button:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-alt:before, .fa-phone-flip:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-fast-forward:before, .fa-forward-fast:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-parking:before, .fa-square-parking:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-bus-alt:before, .fa-bus-simple:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal-5:before, .fa-signal-perfect:before, .fa-signal:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-home-lg:before, .fa-house-chimney:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-balance-scale-left:before, .fa-scale-unbalanced:before {
  content: "";
}

.fa-sort-asc:before, .fa-sort-up:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-arrow-rotate-right:before, .fa-redo:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-calendar-times:before, .fa-calendar-xmark:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-user-cog:before, .fa-user-gear:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-digging:before, .fa-person-digging:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple-med:before, .fa-gauge-simple:before, .fa-tachometer-average:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-quote-right-alt:before, .fa-quote-right:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-external-link-alt:before, .fa-up-right-from-square:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-bible:before, .fa-book-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-medkit:before, .fa-suitcase-medical:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-female:before, .fa-person-dress:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-briefcase-clock:before, .fa-business-time:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-birthday-cake:before, .fa-cake-candles:before, .fa-cake:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-angle-double-up:before, .fa-angles-up:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-hourglass-empty:before, .fa-hourglass:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera-alt:before, .fa-camera:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-alt:before, .fa-prescription-bottle-medical:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-arrow-rotate-left:before, .fa-undo:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-list-alt:before, .fa-rectangle-list:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-arrow-alt-circle-left:before, .fa-circle-left:before {
  content: "";
}

.fa-subway:before, .fa-train-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-alt:before, .fa-crop-simple:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before, .fa-hands-asl-interpreting:before {
  content: "";
}

.fa-cog:before, .fa-gear:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-square-root-alt:before, .fa-square-root-variable:before {
  content: "";
}

.fa-clock-four:before, .fa-clock:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-clinic-medical:before, .fa-house-chimney-medical:before {
  content: "";
}

.fa-temperature-3:before, .fa-temperature-three-quarters:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-mobile-android-alt:before, .fa-mobile-screen:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-battery-3:before, .fa-battery-half:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-sliders-h:before, .fa-sliders:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-ellipsis-v:before, .fa-ellipsis-vertical:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-long-arrow-alt-right:before, .fa-right-long:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-teletype:before, .fa-tty:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-hiking:before, .fa-person-hiking:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-backspace:before, .fa-delete-left:before {
  content: "";
}

.fa-eye-dropper-empty:before, .fa-eye-dropper:before, .fa-eyedropper:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-mobile-android:before, .fa-mobile-phone:before, .fa-mobile:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-dead:before, .fa-book-skull:before {
  content: "";
}

.fa-drivers-license:before, .fa-id-card:before {
  content: "";
}

.fa-dedent:before, .fa-outdent:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-home-alt:before, .fa-home-lg-alt:before, .fa-home:before, .fa-house:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-file-medical:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-redo-alt:before, .fa-rotate-forward:before, .fa-rotate-right:before {
  content: "";
}

.fa-cutlery:before, .fa-utensils:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-broadcast-tower:before, .fa-tower-broadcast:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-long-arrow-alt-up:before, .fa-up-long:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen-sign:before, .fa-yen:before {
  content: "";
}

.fa-rouble:before, .fa-rub:before, .fa-ruble-sign:before, .fa-ruble:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-arrow-alt-circle-down:before, .fa-circle-down:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-angle-down:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis-h:before, .fa-ellipsis:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-first-aid:before, .fa-kit-medical:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-credit-card-alt:before, .fa-credit-card:before {
  content: "";
}

.fa-automobile:before, .fa-car:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-h-square:before, .fa-square-h:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-temperature-4:before, .fa-temperature-full:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-hands-helping:before, .fa-handshake-angle:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-earth-america:before, .fa-earth-americas:before, .fa-earth:before, .fa-globe-americas:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-battery-0:before, .fa-battery-empty:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt-fast:before, .fa-tachometer-alt:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-hospital-alt:before, .fa-hospital-wide:before, .fa-hospital:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-blind:before, .fa-person-walking-with-cane:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-boxes-alt:before, .fa-boxes-stacked:before, .fa-boxes:before {
  content: "";
}

.fa-chain:before, .fa-link:before {
  content: "";
}

.fa-assistive-listening-systems:before, .fa-ear-listen:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-ping-pong-paddle-ball:before, .fa-table-tennis-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-diagnoses:before, .fa-person-dots-from-line:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-edit:before, .fa-file-pen:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-pen-square:before, .fa-pencil-square:before, .fa-square-pen:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-5:before, .fa-battery-full:before, .fa-battery:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-dots:before, .fa-list-ul:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-alt:before, .fa-landmark-dome:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-television:before, .fa-tv-alt:before, .fa-tv:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-shipping-fast:before, .fa-truck-fast:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-adjust:before, .fa-circle-half-stroke:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-baseball-ball:before, .fa-baseball:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-volume-mute:before, .fa-volume-times:before, .fa-volume-xmark:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-grip-horizontal:before, .fa-grip:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-phone-square:before, .fa-square-phone:before {
  content: "";
}

.fa-add:before, .fa-plus:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before, .fa-xmark:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-teacher:before, .fa-chalkboard-user:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left-alt:before, .fa-quote-left:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-ils:before, .fa-shekel-sign:before, .fa-shekel:before, .fa-sheqel-sign:before, .fa-sheqel:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-tablet-android:before, .fa-tablet:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-alt:before, .fa-car-rear:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-2:before, .fa-battery-quarter:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-thumb-tack:before, .fa-thumbtack:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-cogs:before, .fa-gears:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender-alt:before, .fa-transgender:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-alt:before, .fa-ticket-simple:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angle-double-left:before, .fa-angles-left:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-arrow-right-from-file:before, .fa-file-export:before {
  content: "";
}

.fa-shield-blank:before, .fa-shield:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-chevron-circle-left:before, .fa-circle-chevron-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-magic:before, .fa-wand-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-carrot:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-wine-glass-alt:before, .fa-wine-glass-empty:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-biking:before, .fa-person-biking:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-expand-alt:before, .fa-up-right-and-down-left-from-center:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-arrows-alt-v:before, .fa-up-down:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-chain-broken:before, .fa-chain-slash:before, .fa-link-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-alt:before, .fa-fire-flame-curved:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-angry:before, .fa-face-angry:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-feed:before, .fa-rss:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-balance-scale:before, .fa-scale-balanced:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer-fast:before, .fa-tachometer:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-desktop-alt:before, .fa-desktop:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-4:before, .fa-battery-three-quarters:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-fist-raised:before, .fa-hand-fist:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-rectangle-times:before, .fa-rectangle-xmark:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-exclamation-triangle:before, .fa-triangle-exclamation:before, .fa-warning:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-mail-forward:before, .fa-share:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-exchange-alt:before, .fa-right-left:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-ladder-water:before, .fa-swimming-pool:before, .fa-water-ladder:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-alt:before, .fa-wheelchair-move:before {
  content: "";
}

.fa-level-down-alt:before, .fa-turn-down:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-envelope-square:before, .fa-square-envelope:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-band-aid:before, .fa-bandage:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-alt:before, .fa-id-card-clip:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-allergies:before, .fa-hand-dots:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-coffee:before, .fa-mug-saucer:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-user-alt:before, .fa-user-large:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-try:before, .fa-turkish-lira-sign:before, .fa-turkish-lira:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-cog:before, .fa-users-gear:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-bank:before, .fa-building-columns:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-handshake-alt:before, .fa-handshake-simple:before {
  content: "";
}

.fa-fighter-jet:before, .fa-jet-fighter:before {
  content: "";
}

.fa-share-alt-square:before, .fa-square-share-nodes:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-video-camera:before, .fa-video:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-level-up-alt:before, .fa-turn-up:before {
  content: "";
}

.fa-sr-only, .fa-sr-only-focusable:not(:focus), .sr-only, .sr-only-focusable:not(:focus) {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:host, :root {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.f88bf5bd.woff2") format("woff2"), url("fa-brands-400.4ca064ed.ttf") format("truetype");
}

.fa-brands, .fab {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-js-square:before, .fa-square-js:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-reddit-square:before, .fa-square-reddit:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-instagram-square:before, .fa-square-instagram:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-hacker-news-square:before, .fa-square-hacker-news:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-snapchat-square:before, .fa-square-snapchat:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-font-awesome-alt:before, .fa-square-font-awesome-stroke:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-dribbble-square:before, .fa-square-dribbble:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-rendact:before, .fa-wpressr:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before, .fa-font-awesome:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-github-square:before, .fa-square-github:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-gitlab-square:before, .fa-square-gitlab:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-odnoklassniki-square:before, .fa-square-odnoklassniki:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-pinterest-square:before, .fa-square-pinterest:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-google-plus-square:before, .fa-square-google-plus:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-pied-piper-square:before, .fa-square-pied-piper:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-facebook-square:before, .fa-square-facebook:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-lastfm-square:before, .fa-square-lastfm:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat-ghost:before, .fa-snapchat:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-behance-square:before, .fa-square-behance:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-git-square:before, .fa-square-git:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram-plane:before, .fa-telegram:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack-hash:before, .fa-slack:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium-m:before, .fa-medium:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:host, :root {
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.dfc33a0e.woff2") format("woff2"), url("fa-regular-400.1089164c.ttf") format("truetype");
}

.fa-regular, .far {
  font-weight: 400;
}

:host, :root {
  --fa-style-family-classic: "Font Awesome 6 Free";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.1f6e23c0.woff2") format("woff2"), url("fa-solid-900.89d7d2cc.ttf") format("truetype");
}

.fa-solid, .fas {
  font-weight: 900;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("fa-brands-400.f88bf5bd.woff2") format("woff2"), url("fa-brands-400.4ca064ed.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 900;
  src: url("fa-solid-900.1f6e23c0.woff2") format("woff2"), url("fa-solid-900.89d7d2cc.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-display: block;
  font-weight: 400;
  src: url("fa-regular-400.dfc33a0e.woff2") format("woff2"), url("fa-regular-400.1089164c.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-solid-900.1f6e23c0.woff2") format("woff2"), url("fa-solid-900.89d7d2cc.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-brands-400.f88bf5bd.woff2") format("woff2"), url("fa-brands-400.4ca064ed.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-regular-400.dfc33a0e.woff2") format("woff2"), url("fa-regular-400.1089164c.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-v4compatibility.cedb471a.woff2") format("woff2"), url("fa-v4compatibility.55d9d105.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}

* {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

@media only screen and (max-width: 61.25em) {
  html {
    font-size: 60%;
  }
}

.body__login {
  min-height: calc(100vh - 8vw);
  background-color: #000;
  justify-content: center;
  align-items: center;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  display: flex;
}

.body__main {
  color: #615551;
  min-height: calc(100vh - 8vw);
  background-color: #f5eeee;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: Nunito Sans, sans-serif;
  font-weight: 400;
  line-height: 1.6;
}

.container__login {
  max-width: 190rem;
  background-color: #15172b;
  border-radius: 9px;
  margin: 1vw auto;
  overflow: hidden;
  box-shadow: 0 2rem 6rem .5rem #61555133;
}

.container__main {
  max-width: 190rem;
  min-height: calc(100vh - 8vw);
  grid-row-gap: .4rem;
  background-color: #fff;
  border-radius: 9px;
  grid-template-rows: 6rem 2rem auto minmax(51rem, auto);
  grid-template-columns: minmax(0, 1fr);
  margin: 1vw auto;
  display: grid;
  box-shadow: 0 2rem 6rem .5rem #61555133;
}

@media only screen and (max-width: 78.15em) {
  .container__main {
    max-width: 100%;
    border-radius: 0;
    margin: 0;
  }
}

.login {
  box-sizing: border-box;
  height: 500px;
  width: 320px;
  background-color: #15172b;
  border-radius: 20px;
  padding: 20px;
}

.login__title {
  color: #eee;
  text-align: center;
  margin-top: 3rem;
  font-family: sans-serif;
  font-size: 3.6rem;
  font-weight: 600;
}

.login__subtitle {
  color: #eee;
  text-align: center;
  margin-top: 1rem;
  font-family: sans-serif;
  font-size: 1.6rem;
  font-weight: 600;
}

.login__message {
  color: #dc2f55;
  text-align: center;
  margin-top: 3rem;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.login__input-container {
  height: 50px;
  width: 100%;
  position: relative;
}

.login__ic1 {
  margin-top: 4rem;
}

.login__ic2 {
  margin-top: 3rem;
}

.login__input {
  box-sizing: border-box;
  color: #eee;
  height: 100%;
  width: 100%;
  background-color: #303245;
  border: 0;
  border-radius: 12px;
  outline: 0;
  padding: 4px 20px 0;
  font-size: 1.6rem;
}

.login__cut {
  height: 20px;
  width: 76px;
  background-color: #15172b;
  border-radius: 10px;
  transition: transform .2s;
  position: absolute;
  top: -20px;
  left: 20px;
  transform: translateY(0);
}

.login__input:focus ~ .login__cut, .login__input:not(:placeholder-shown) ~ .login__cut {
  transform: translateY(8px);
}

.login__placeholder {
  color: #65657b;
  pointer-events: none;
  transform-origin: 0;
  font-family: sans-serif;
  font-size: 1.6rem;
  line-height: 14px;
  transition: transform .2s, color .2s;
  position: absolute;
  top: 20px;
  left: 20px;
}

.login__input:focus ~ .login__placeholder, .login__input:not(:placeholder-shown) ~ .login__placeholder {
  transform: translateY(-30px)translateX(10px)scale(.75);
}

.login__input:not(:placeholder-shown) ~ .login__placeholder {
  color: #808097;
}

.login__input:focus ~ .login__placeholder {
  color: #dc2f55;
}

.fa-eye-slash, .fa-eye {
  color: #65657b;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 250px;
}

.login__submit {
  box-sizing: border-box;
  color: #eee;
  cursor: pointer;
  height: 50px;
  text-align: center;
  width: 100%;
  background-color: #08d;
  border: 0;
  border-radius: 12px;
  margin-top: 38px;
  font-size: 18px;
}

.login__submit:hover {
  color: #b1b0f3;
  background-color: #07c;
}

.login__submit:active {
  background-color: #06b;
}

.login__spinner svg {
  height: 3rem;
  width: 3rem;
  fill: #08d;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  background-image: linear-gradient(to bottom right, #fbdb89, #f48982);
  border: none;
  border-radius: 10rem;
  align-items: center;
  transition: all .2s;
  display: flex;
}

.btn:hover {
  transform: scale(1.05);
}

.btn:focus {
  outline: none;
}

.btn {
  background-color: #fff;
  background-image: none;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.btn svg {
  height: 2rem;
  width: 2rem;
}

.link:link, .link:visited {
  color: #918581;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 50%;
}

.spinner svg {
  height: 4rem;
  width: 4rem;
  fill: #08d;
  animation: rotate 2s linear infinite;
}

.message, .error {
  max-width: 40rem;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
}

.message svg, .error svg {
  height: 3rem;
  width: 3rem;
  fill: #3863be;
  transform: translateY(-.3rem);
}

.message p, .error p {
  margin-left: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
}

.header {
  z-index: 10;
  background-color: #f9f5f3;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}

.header__logo {
  height: 2.2rem;
  margin-left: 2rem;
  margin-right: 1rem;
  display: block;
}

@media only screen and (max-width: 31.25em) {
  .header__logo {
    height: 1.7rem;
    margin-left: 1rem;
    margin-right: .8rem;
  }
}

.search {
  background-color: #fff;
  border-radius: .5rem;
  align-items: center;
  padding-left: 1.5rem;
  transition: all .3s;
  display: flex;
  position: relative;
}

.search:focus-within {
  transform: translateY(-2px);
  box-shadow: 0 .7rem 3rem #61555114;
}

.search__field {
  color: inherit;
  width: 28rem;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 1.3rem;
}

.search__field:focus {
  outline: none;
}

.search__field::placeholder {
  color: #d3c7c3;
}

@media only screen and (max-width: 78.15em) {
  .search__field {
    width: 26rem;
  }
}

@media only screen and (max-width: 61.25em) {
  .search__field {
    width: auto;
  }

  .search__field::placeholder {
    color: #fff;
    display: none;
  }
}

@media only screen and (max-width: 31.25em) {
  .search__field {
    width: 6.5rem;
  }
}

.search__btn {
  font-family: inherit;
  font-weight: 600;
}

.nav {
  align-self: stretch;
  display: flex;
}

.nav__list {
  height: 100%;
  list-style: none;
  display: flex;
}

.nav__item {
  position: relative;
}

.nav__item:hover {
  background-color: #f2efee;
}

.nav__dropdown {
  z-index: 10;
  background-color: #f9f5f3;
  display: none;
  box-shadow: 0 .7rem 3rem #61555114;
}

.nav__dropdown:hover, .nav__btn--masterdata:hover + .nav__dropdown, .nav__dropdown:hover, .nav__btn--profile:hover + .nav__dropdown, .nav__dropdown:hover, .nav__btn--package:hover + .nav__dropdown, .nav__dropdown:hover, .nav__btn--pos:hover + .nav__dropdown, .nav__dropdown:hover, .nav__btn--finance:hover + .nav__dropdown {
  display: block;
}

.nav__btn, .nav__btn--apps {
  height: 100%;
  color: inherit;
  text-transform: capitalize;
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding: 0 .8rem;
  font-family: inherit;
  font-size: 1.3rem;
  font-weight: 700;
  transition: all .3s;
  display: flex;
}

.nav__btn--dropdown, .nav__btn--apps--dropdown {
  min-height: 5rem;
  width: 100%;
}

.nav__btn svg, .nav__btn--apps svg {
  height: 2.2rem;
  width: 2.2rem;
  fill: #3863be;
  margin-right: .5rem;
  transform: translateY(-1px);
}

.nav__btn:focus, .nav__btn--apps:focus {
  outline: none;
}

.nav__btn:hover, .nav__btn--apps:hover {
  background-color: #f2efee;
}

.nav__profile {
  width: 4.5rem;
  height: 4.5rem;
  color: #fff;
  text-align: center;
  background: #3863be;
  border-radius: 50%;
  font-family: inherit;
  font-size: 1.8rem;
  line-height: 4.5rem;
}

.nav__profile-name {
  text-transform: capitalize;
  font-family: inherit;
  display: none;
}

.nav__chart-name, .nav__cart-name {
  text-transform: uppercase;
  font-family: inherit;
  display: none;
}

.filter__block {
  align-self: stretch;
  position: relative;
}

.filter__items {
  height: 100%;
  align-items: center;
  display: flex;
}

.nav__btn--filterlist, .nav__btn--camera {
  border: .5px solid #f2efee;
  border-radius: 0;
}

.filter__box {
  z-index: 10;
  box-sizing: border-box;
  max-height: fit-content;
  background-color: #fff;
  border: none;
  padding: 8px;
  transition: all .5s .2s;
  display: none;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000004d;
}

.filter__box label {
  color: inherit;
  vertical-align: middle;
  margin-right: .5rem;
  font-size: 1.4rem;
  font-weight: 600;
  display: inline;
}

@media only screen and (max-width: 61.25em) {
  .filter__box label {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 31.25em) {
  .filter__box label {
    display: none;
  }
}

.filter__box input, .filter__box select, .filter__box select > option {
  color: inherit;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: .5rem;
  margin-right: 1%;
  padding: .8rem 1rem;
  font-family: inherit;
  font-size: 1.4rem;
  transition: all .2s;
}

.filter__box input::placeholder, .filter__box select::placeholder, .filter__box select > option::placeholder {
  color: #d3c7c3;
}

.filter__box input:focus, .filter__box select:focus, .filter__box select > option:focus {
  background-color: #f9f5f3;
  border: 1px solid #5068f0;
  outline: none;
}

@media only screen and (max-width: 61.25em) {
  .filter__box input, .filter__box select, .filter__box select > option {
    font-size: 1.3rem;
  }
}

.filter__list {
  height: 100%;
  width: 100%;
  list-style: none;
}

.filter {
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 31.25em) {
  .filter__daterange {
    display: block;
  }
}

.appbox {
  z-index: 10;
  box-sizing: border-box;
  max-height: 30rem;
  background-color: #fff;
  border: none;
  border-radius: 26px;
  padding: 8px;
  transition: all .5s .2s;
  display: none;
  position: absolute;
  right: .5rem;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 4px 8px 3px #00000026, 0 1px 3px #0000004d;
}

.appbox:hover, .nav__btn--apps:hover + .appbox {
  display: block;
}

.apps {
  height: 84px;
  vertical-align: top;
  width: 84px;
  padding: 6px;
  list-style-type: none;
  transition: transform .2s cubic-bezier(.333, 0, 0, 1);
  display: inline-block;
  position: relative;
}

.appitem {
  text-align: center;
  width: 80px;
  cursor: pointer;
  border-radius: 8px;
  outline: none;
  margin: 0;
  text-decoration: none;
  position: absolute;
}

.appitem:hover {
  background-color: #f9f5f3;
}

.appitembox {
  height: 45px;
  margin-top: 5px;
}

.applogo {
  height: 40px;
  vertical-align: top;
  width: 40px;
  background-size: 40px 40px;
  display: inline-block;
}

.applogo1 {
  background-image: url("edate_logo.936ef6e7.png");
}

.ifr, .hamburger {
  display: none;
}

.bar {
  width: 20px;
  height: 3px;
  background-color: #101010;
  margin: 4px auto;
  transition: all .3s ease-in-out;
  display: block;
}

@media only screen and (max-width: 61.25em) {
  .nav__list {
    text-align: center;
    background-color: #f9f5f3;
    border-radius: 10px;
    transition: all .3s;
    display: none;
    position: fixed;
    top: 6rem;
    right: 0;
    box-shadow: 0 10px 27px #0000000d;
  }

  .nav__list.active {
    display: block;
    right: 0;
  }

  .nav__item {
    min-height: 5rem;
    margin: 0;
    padding-top: .8rem;
    padding-left: .8rem;
  }

  .nav__profile {
    width: 3rem;
    height: 3rem;
    font-size: 1.6rem;
    line-height: 3rem;
  }

  .nav__profile-name {
    margin-left: .6rem;
    font-size: 1.4rem;
    display: inline;
  }

  .nav__chart-name, .nav__cart-name {
    font-size: 1.3rem;
    display: inline;
  }

  .cart-count {
    left: 75px;
  }

  .hamburger {
    cursor: pointer;
    margin-top: 2rem;
    padding-left: 2rem;
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(7px)rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-7px)rotate(-45deg);
  }
}

@media only screen and (max-width: 31.25em) {
  .appbox {
    right: 0;
  }
}

.actionbtns, .chartbtns {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 1.5rem;
  display: flex;
}

.registrybtns {
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
  margin-left: 1.5rem;
  display: flex;
}

.registrybtn {
  box-sizing: border-box;
  user-select: none;
  width: 10rem;
  color: inherit;
  background-color: #fff;
  border: none;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.4rem;
  line-height: 29px;
  transition: all .3s;
  display: block;
  position: relative;
}

.registrybtn:hover {
  color: #3863be;
  cursor: pointer;
  font-weight: bold;
  transform: translateY(-2px);
}

.registrybtn.active {
  color: #3863be;
  border-bottom: 2px solid #007bff;
  font-weight: bold;
}

.actionbtn {
  box-sizing: border-box;
  user-select: none;
  width: 10rem;
  color: inherit;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  margin-left: .4rem;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  display: block;
  position: relative;
  box-shadow: 0 2px 5px #d5d9d980;
}

.item-size {
  opacity: 0;
  visibility: hidden;
  flex-flow: wrap;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 1s;
  display: flex;
  position: absolute;
  bottom: 6rem;
  left: 1rem;
}

.popup-item-size {
  border-bottom: 1px solid #0000001a;
  grid-template-columns: 50% 50%;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 10px;
  display: grid;
}

.catsizebtn {
  box-sizing: border-box;
  user-select: none;
  width: 10.1rem;
  color: inherit;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  display: block;
  position: relative;
  box-shadow: 0 2px 5px #d5d9d980;
}

.popupcatsizebtn {
  box-sizing: border-box;
  user-select: none;
  width: 23rem;
  color: inherit;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 4px;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  display: block;
  position: relative;
  box-shadow: 0 2px 5px #d5d9d980;
}

@media only screen and (max-width: 31.25em) {
  .popupcatsizebtn {
    width: auto;
  }
}

.popupcatsizebtnselected {
  color: #fff;
  background-color: #000;
}

.chartbtn {
  border-radius: 20px;
  margin-left: 2rem;
}

.actionbtn:disabled, .catsizebtn:disabled, .popupcatsizebtn:disabled, .popup-addtocart:disabled, .cart-camera:disabled {
  opacity: .5;
  pointer-events: none;
}

.actionbtn:not(.disabled):hover, .catsizebtn:not(.disabled):hover, .popupcatsizebtn:not(.disabled):hover {
  cursor: pointer;
  color: #fff;
  background: #b9b5b5e6;
}

.dropdownbtn {
  box-sizing: border-box;
  user-select: none;
  width: 10rem;
  background-color: #fff;
  border: 0 solid #fff;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  display: block;
  position: relative;
}

.actionbtn__dropdown {
  z-index: 25;
  margin-top: -.7rem;
  margin-left: 1rem;
  display: none;
  position: absolute;
  box-shadow: 0 10px 10px #0006;
}

.dropdownbtn:disabled {
  opacity: .5;
}

.dropdownbtn:not(.disabled):hover {
  cursor: pointer;
  color: #fff;
  background: #b3b3b3;
}

.doc__btn {
  cursor: pointer;
  background: none;
  border: none;
  align-items: center;
  padding-right: 1.5rem;
  transition: all .3s;
  display: inline-flex;
  position: relative;
  left: 45%;
}

.doc__btn svg {
  height: 4rem;
  width: 4rem;
  fill: #3863be;
  transform: translateY(-1px);
}

.doc__btn:hover {
  transform: translateY(-2px);
}

.doc__btn--bill_whatsapp svg {
  fill: #25d366;
}

.details {
  z-index: 5;
  align-items: flex-start;
  padding: 0 1rem 1rem;
  display: flex;
}

span {
  font-family: inherit;
  font-size: 1.3rem;
}

input {
  border: 1px solid #d5d9d9;
  font-family: inherit;
  font-size: 1.3rem;
}

.fa-check-square, .fa-square {
  color: #918581;
  margin-right: .8rem;
}

.fa-plus, .fa-minus {
  color: #918581;
  font-size: 62.5%;
}

.fa-times-circle {
  color: #e61515;
  font-size: 110%;
}

.fa-plus-circle {
  color: #18cc27;
  cursor: pointer;
  font-size: 110%;
}

.fa-caret-down {
  color: #918581;
  margin-left: .8rem;
}

.fa-file-invoice-dollar, .edit-item {
  color: #3863be;
  font-size: 120%;
}

.hidden {
  display: none;
}

.tabulator-footer {
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.tabulator, .tabulator-page, .tabulator-page-size, .tabulator-menu-item {
  color: inherit;
  font-family: inherit;
  font-size: 13px;
}

.tabulator-paginator {
  align-items: center;
  margin: .5rem 0;
  display: block;
  position: relative;
}

.tabulator-row.tabulator-selected {
  background-color: #e3eefa !important;
}

.add-package-window, .add-customer-window, .add-treatment-window, .add-item-window, .billdoc-window, .add-cartcheckout-window, .add-catalogmaster-window, .add-registry_selectfield-window, .add-registry_selectlist-window, .add-registry_keyobject-window, .add-registry_reftype-window {
  max-height: 95%;
  width: 70%;
  z-index: 30;
  background-color: #fff;
  border-radius: 3px;
  padding: 5rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #00000040;
}

@media only screen and (max-width: 78.15em) {
  .add-package-window, .add-customer-window, .add-treatment-window, .add-item-window, .billdoc-window, .add-cartcheckout-window, .add-catalogmaster-window, .add-registry_selectfield-window, .add-registry_selectlist-window, .add-registry_keyobject-window, .add-registry_reftype-window {
    width: 73%;
  }
}

@media only screen and (max-width: 61.25em) {
  .add-package-window, .add-customer-window, .add-treatment-window, .add-item-window, .billdoc-window, .add-cartcheckout-window, .add-catalogmaster-window, .add-registry_selectfield-window, .add-registry_selectlist-window, .add-registry_keyobject-window, .add-registry_reftype-window {
    width: 60%;
    padding: 3rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .add-package-window, .add-customer-window, .add-treatment-window, .add-item-window, .billdoc-window, .add-cartcheckout-window, .add-catalogmaster-window, .add-registry_selectfield-window, .add-registry_selectlist-window, .add-registry_keyobject-window, .add-registry_reftype-window {
    width: 90%;
  }
}

.btn--close-modal-package, .btn--close-modal-customer, .btn--close-modal-treatment, .btn--close-modal-item, .btn--close-modal-billdoc, .btn--close-modal-content, .btn--close-modal-camera, .btn--close-modal-cart-checkout, .btn--close-modal-catalogmaster, .btn--close-modal-registry_selectfield, .btn--close-modal-registry_selectlist, .btn--close-modal-registry_keyobject, .btn--close-modal-registry_reftype {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 3.5rem;
  position: absolute;
  top: .5rem;
  right: 3.8rem;
}

.overlay-package, .overlay-customer, .overlay-treatment, .overlay-item, .overlay-billdoc, .overlay-cartcheckout, .overlay-catalogmaster, .overlay-registry_selectfield, .overlay-registry_selectlist, .overlay-registry_keyobject, .overlay-registry_reftype {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(.5px);
  z-index: 20;
  background-color: #0006;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: 0;
}

.upload-cartcheckout {
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 4rem;
  display: grid;
}

@media only screen and (max-width: 61.25em) {
  .upload-cartcheckout {
    grid-template-columns: 1fr;
    gap: 2rem 6rem;
  }
}

.upload-cartcheckout__column {
  height: 100%;
  align-items: center;
  gap: 1.5rem;
  display: block;
  overflow-y: auto;
}

@media only screen and (max-width: 61.25em) {
  .upload-cartcheckout__column {
    grid-template-columns: 1fr 1.8fr;
    gap: 1rem;
  }
}

.upload-cartcheckout__column label {
  color: inherit;
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
}

.upload-cartcheckout__column button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.upload-cartcheckout__heading {
  text-transform: uppercase;
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.upload-cartcheckout__subheading {
  margin-bottom: 1rem;
  font-size: 1.3rem;
  font-weight: 600;
}

.upload-cartcheckout__btn {
  grid-column: 1 / -1;
  justify-self: center;
  margin-left: 3rem;
}

.add-cartcheckout-form {
  color: inherit;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: .5rem;
  margin-right: 1%;
  padding: .8rem 1rem;
  font-family: inherit;
  font-size: 1.3rem;
  transition: all .2s;
}

.add-cartcheckout-form::placeholder {
  color: #d3c7c3;
}

.add-cartcheckout-form:focus {
  background-color: #f9f5f3;
  border: 1px solid #5068f0;
  outline: none;
}

.upload-package, .upload-customer, .upload-treatment, .upload-item, .upload-catalogmaster, .upload-registry_selectfield, .upload-registry_selectlist, .upload-registry_keyobject, .upload-registry_reftype {
  grid-template-columns: 1fr 1fr;
  align-items: start;
  gap: 4rem;
  display: grid;
}

@media only screen and (max-width: 61.25em) {
  .upload-package, .upload-customer, .upload-treatment, .upload-item, .upload-catalogmaster, .upload-registry_selectfield, .upload-registry_selectlist, .upload-registry_keyobject, .upload-registry_reftype {
    grid-template-columns: 1fr;
    gap: 2rem 6rem;
  }
}

.upload-package__column, .upload-customer__column, .upload-treatment__column, .upload-item__column, .upload-catalogmaster__column, .upload-registry_selectfield__column, .upload-registry_selectlist__column, .upload-registry_keyobject__column, .upload-registry_reftype__column {
  height: 100%;
  align-items: center;
  gap: 1.5rem;
  display: block;
  overflow-y: auto;
}

@media only screen and (max-width: 61.25em) {
  .upload-package__column, .upload-customer__column, .upload-treatment__column, .upload-item__column, .upload-catalogmaster__column, .upload-registry_selectfield__column, .upload-registry_selectlist__column, .upload-registry_keyobject__column, .upload-registry_reftype__column {
    grid-template-columns: 1fr 1.8fr;
    gap: 1rem;
  }
}

.upload-package__column label, .upload-customer__column label, .upload-treatment__column label, .upload-item__column label, .upload-catalogmaster__column label, .upload-registry_selectfield__column label, .upload-registry_selectlist__column label, .upload-registry_keyobject__column label, .upload-registry_reftype__column label {
  color: inherit;
  font-size: 1.3rem;
  font-weight: 600;
  display: block;
}

.upload-package__column input, .upload-package__column select, .upload-package__column select > option, .upload-customer__column input, .upload-customer__column select, .upload-customer__column select > option, .upload-treatment__column input, .upload-treatment__column select, .upload-treatment__column select > option, .upload-item__column input, .upload-item__column select, .upload-item__column select > option, .upload-catalogmaster__column input, .upload-catalogmaster__column select, .upload-catalogmaster__column select > option, .upload-registry_selectfield__column input, .upload-registry_selectfield__column select, .upload-registry_selectfield__column select > option, .upload-registry_selectlist__column input, .upload-registry_selectlist__column select, .upload-registry_selectlist__column select > option, .upload-registry_keyobject__column input, .upload-registry_keyobject__column select, .upload-registry_keyobject__column select > option, .upload-registry_reftype__column input, .upload-registry_reftype__column select, .upload-registry_reftype__column select > option {
  color: inherit;
  vertical-align: middle;
  border: 1px solid #ddd;
  border-radius: .5rem;
  margin-right: 1%;
  padding: .8rem 1rem;
  font-family: inherit;
  font-size: 1.3rem;
  transition: all .2s;
}

.upload-package__column input::placeholder, .upload-package__column select::placeholder, .upload-package__column select > option::placeholder, .upload-customer__column input::placeholder, .upload-customer__column select::placeholder, .upload-customer__column select > option::placeholder, .upload-treatment__column input::placeholder, .upload-treatment__column select::placeholder, .upload-treatment__column select > option::placeholder, .upload-item__column input::placeholder, .upload-item__column select::placeholder, .upload-item__column select > option::placeholder, .upload-catalogmaster__column input::placeholder, .upload-catalogmaster__column select::placeholder, .upload-catalogmaster__column select > option::placeholder, .upload-registry_selectfield__column input::placeholder, .upload-registry_selectfield__column select::placeholder, .upload-registry_selectfield__column select > option::placeholder, .upload-registry_selectlist__column input::placeholder, .upload-registry_selectlist__column select::placeholder, .upload-registry_selectlist__column select > option::placeholder, .upload-registry_keyobject__column input::placeholder, .upload-registry_keyobject__column select::placeholder, .upload-registry_keyobject__column select > option::placeholder, .upload-registry_reftype__column input::placeholder, .upload-registry_reftype__column select::placeholder, .upload-registry_reftype__column select > option::placeholder {
  color: #d3c7c3;
}

.upload-package__column input:focus, .upload-package__column select:focus, .upload-package__column select > option:focus, .upload-customer__column input:focus, .upload-customer__column select:focus, .upload-customer__column select > option:focus, .upload-treatment__column input:focus, .upload-treatment__column select:focus, .upload-treatment__column select > option:focus, .upload-item__column input:focus, .upload-item__column select:focus, .upload-item__column select > option:focus, .upload-catalogmaster__column input:focus, .upload-catalogmaster__column select:focus, .upload-catalogmaster__column select > option:focus, .upload-registry_selectfield__column input:focus, .upload-registry_selectfield__column select:focus, .upload-registry_selectfield__column select > option:focus, .upload-registry_selectlist__column input:focus, .upload-registry_selectlist__column select:focus, .upload-registry_selectlist__column select > option:focus, .upload-registry_keyobject__column input:focus, .upload-registry_keyobject__column select:focus, .upload-registry_keyobject__column select > option:focus, .upload-registry_reftype__column input:focus, .upload-registry_reftype__column select:focus, .upload-registry_reftype__column select > option:focus {
  background-color: #f9f5f3;
  border: 1px solid #5068f0;
  outline: none;
}

.upload-package__column button, .upload-customer__column button, .upload-treatment__column button, .upload-item__column button, .upload-catalogmaster__column button, .upload-registry_selectfield__column button, .upload-registry_selectlist__column button, .upload-registry_keyobject__column button, .upload-registry_reftype__column button {
  grid-column: 1 / span 2;
  justify-self: center;
  margin-top: 1rem;
}

.upload-package__heading, .upload-customer__heading, .upload-treatment__heading, .upload-item__heading, .upload-catalogmaster__heading, .upload-registry_selectfield__heading, .upload-registry_selectlist__heading, .upload-registry_keyobject__heading, .upload-registry_reftype__heading {
  text-transform: uppercase;
  grid-column: 1 / -1;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
}

.upload-package__btn, .upload-customer__btn, .upload-treatment__btn, .upload-item__btn, .upload-catalogmaster__btn, .upload-registry_selectfield__btn, .upload-registry_selectlist__btn, .upload-registry_keyobject__btn, .upload-registry_reftype__btn {
  grid-column: 1 / -1;
  justify-self: center;
}

.upload-bill-package__btn, .upload-addcart-package__btn, .upload-cartcheckoutmanual__btn {
  justify-self: center;
  margin-left: 2rem;
}

.upload-package__btns, .upload-cartcheckout__btns {
  grid-column: 1 / -1;
  justify-content: center;
  display: flex;
}

.popup-content__btns {
  justify-content: center;
  display: flex;
}

.required:after {
  color: #e32;
  content: " *";
  display: inline;
}

.upload-row-padding {
  margin-bottom: 1rem;
}

.upload-section-row-padding {
  margin-bottom: 2rem;
}

.upload-row-inline {
  flex-direction: row;
  display: flex;
}

.add-form-span {
  vertical-align: bottom;
}

.upload_row_separator_line {
  border-bottom: 1px solid #0000002e;
  padding-bottom: 16px;
}

.add-package-form-customer-matchlist, .add-catalogmaster-form-item-matchlist, .add-registry_selectlist-form-keyfield-matchlist, .add-registry_reftype-form-keyobject-matchlist {
  width: 17.3rem;
  z-index: 40;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 2px 5px #d5d9d980;
}

.add-package-form-customer-matchlist__details, .add-catalogmaster-form-item-matchlist__details, .add-registry_selectlist-form-keyfield-matchlist__details, .add-registry_reftype-form-keyobject-matchlist__details {
  z-index: 40;
  padding: .5rem 0 .5rem .4rem;
  display: block;
  position: relative;
}

.add-package-form-customer-matchlist__details:hover, .add-catalogmaster-form-item-matchlist__details:hover, .add-registry_selectlist-form-keyfield-matchlist__details:hover, .add-registry_reftype-form-keyobject-matchlist__details:hover {
  cursor: default;
  background: #f2efee;
}

.add-package-form-customer-matchlist__details-selected, .add-catalogmaster-form-item-matchlist__details-selected, .add-registry_selectlist-form-keyfield-matchlist__details-selected, .add-registry_reftype-form-keyobject-matchlist__details-selected {
  background: #f2efee;
}

.add-customer-form-gender {
  vertical-align: middle;
  margin-right: 4%;
}

.add-package-form-treatment-matchlist {
  width: 17.3rem;
  z-index: 40;
  background-color: #fff;
  position: absolute;
  box-shadow: 0 2px 5px #d5d9d980;
}

.add-package-form-treatment-matchlist__details {
  padding: .5rem 0 .5rem .4rem;
  display: block;
  position: relative;
}

.add-package-form-treatment-matchlist__details:hover {
  cursor: default;
  background: #f2efee;
}

.add-package-form-treatment-matchlist__details-selected {
  background: #f2efee;
}

.overlay-error-message, .overlay-content {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(.5px);
  z-index: 50;
  background-color: #0006;
  position: fixed;
  top: 0;
  left: 0;
}

.popup-message {
  width: 50rem;
  z-index: 60;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  padding: 5rem 6rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #00000040;
}

.popup-message svg {
  height: 3rem;
  width: 3rem;
  fill: #3863be;
  vertical-align: middle;
  margin-right: .7rem;
  display: inline;
  transform: translateY(-1px);
}

@media only screen and (max-width: 61.25em) {
  .popup-message {
    width: auto;
  }
}

.messagebtn {
  box-sizing: border-box;
  width: 10rem;
  color: inherit;
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  margin: 2.5rem auto 0;
  padding: 0 10px 0 11px;
  font-family: inherit;
  font-size: 1.3rem;
  line-height: 29px;
  box-shadow: 0 2px 5px #d5d9d980;
}

.messagebtn:hover {
  cursor: pointer;
  color: #fff;
  background: #0003;
}

.popup-message-text {
  color: inherit;
  vertical-align: middle;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  display: inline;
}

@media only screen and (max-width: 61.25em) {
  .popup-message-text {
    font-size: 1.3rem;
  }
}

.add-item-drag-area, .add-treatment-drag-area {
  width: 90%;
  color: inherit;
  border: 3px dashed #e0eafc;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  font-family: inherit;
  display: flex;
}

.add-item-drag-edit, .add-treatment-drag-edit {
  color: #3863be;
  cursor: pointer;
  margin-left: .5rem;
  font-size: 1.4rem;
}

.add-item-drag-area .add-item-drag-icon, .add-treatment-drag-area .add-treatment-drag-icon {
  color: #3863be;
  font-size: 5rem;
}

.add-item-drag-area .add-item-drag-header, .add-treatment-drag-area .add-treatment-drag-header {
  font-size: 2rem;
  font-weight: 500;
}

.add-item-drag-area .add-item-drag-support, .add-treatment-drag-area .add-treatment-drag-support {
  margin: 10px 0 15px;
  font-size: 12px;
}

.add-item-drag-area .add-item-drag-browsebutton, .add-treatment-drag-area .add-treatment-drag-browsebutton {
  color: #3863be;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 500;
}

.add-item-drag-area.active, .add-treatment-drag-area.active {
  border: 2px solid #3863be;
}

.add-item-drag-area img, .add-treatment-drag-area img {
  width: 100%;
  height: 736px;
  object-fit: cover;
}

.add-item-image-header, .add-treatment-image-header {
  align-items: center;
  display: flex;
}

.add-item-image-additional-content {
  height: 736px;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.add-item-image-additional-content::-webkit-scrollbar {
  width: 2px;
}

.add-item-image-additional-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.add-item-image-additional-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.add-item-image-additional {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  transition: all .4s;
}

.billdoc-content {
  width: 100%;
  height: 100%;
  color: #555;
  background: #fff;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  position: relative;
}

.clearfix {
  border-bottom: 1px solid #aaa;
  margin-bottom: 10px;
  padding: 10px 0;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

#billdoclogo {
  float: left;
  margin-top: 8px;
}

.receiptheader {
  color: #777;
  margin-bottom: 8px;
  font-family: inherit;
  font-size: 1.2em;
}

#billdoclogo img {
  height: 2.5rem;
}

#billdoccompany {
  float: right;
  text-align: right;
}

#billdocdetails {
  margin-bottom: 30px;
}

#billdocclient {
  float: left;
  padding-left: 6px;
}

#billdocclient .to {
  color: #777;
  text-transform: uppercase;
}

h2.billdoc-cust-name, h2.billdoc-org-name {
  margin: 0;
  font-size: 1.1em;
  font-weight: normal;
}

#billdocinvoice {
  float: right;
  text-align: right;
}

#billdocinvoice h1 {
  text-transform: uppercase;
  margin: 0 0 10px;
  font-size: 1.1em;
  font-weight: normal;
  line-height: 1em;
}

#billdocinvoice .date, #billdocinvoice .method {
  color: #777;
}

#billdoctable {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

#billdoctable th, #billdoctable td {
  text-align: center;
  background: #eee;
  border-bottom: 1px solid #fff;
  padding: 9px;
}

#billdoctable th {
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: bold;
}

#billdoctable td {
  text-align: right;
}

#billdoctable td h3 {
  margin: 0 0 .2em;
  font-weight: normal;
}

#billdoctable .no {
  text-align: center;
}

#billdoctable .id {
  text-align: center;
  background: #ddd;
}

#billdoctable tbody .desc {
  text-align: left;
}

#billdoctable .qty {
  text-align: center;
  background: #ddd;
}

#billdoctable tbody .notes {
  text-align: left;
}

#billdoctable .total {
  text-align: right;
  background: #e3eefa;
}

#billdoctable tbody tr:last-child td {
  border: none;
}

#billdoctable tfoot td {
  white-space: nowrap;
  text-transform: capitalize;
  background: #fff;
  border-top: none;
  border-bottom: .5px solid #aaa;
  padding: 10px 20px;
}

#billdoctable tfoot .billdocgrandtotal td {
  border-bottom: .5px solid;
}

#billdoctable tfoot tr td:first-child {
  border: none;
}

#billdocthanks {
  margin-bottom: 30px;
  font-size: 1.2em;
}

#billdocnotices {
  padding-bottom: 6px;
}

#billdocnotices .notice, #billdocnotices .terms {
  text-align: justify;
  font-size: .6em;
}

#billdocfooter {
  color: #777;
  width: 100%;
  height: 100%;
  text-align: center;
  border-top: .5px solid #aaa;
  padding: 8px 0;
  position: relative;
  bottom: 0;
}

.shop-content {
  grid-template-columns: repeat(auto-fit, minmax(220px, auto));
  place-content: center;
  margin-bottom: 10px;
  display: grid;
}

.item-box {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
  position: relative;
  box-shadow: 0 1px 4px #2825251a;
}

.item-pic {
  min-height: 88%;
  cursor: pointer;
  overflow: hidden;
}

.item-pic:hover img {
  transform: scale(1.5);
}

.item-img {
  object-fit: cover;
  width: 100%;
  height: 320px;
  transition: all .4s;
}

.popup-flying-img {
  z-index: 80;
  animation: fly_to_cart 1s ease-in-out;
  position: absolute;
}

@keyframes fly_to_cart {
  0% {
    top: 0;
    left: 0;
  }

  45%, 50% {
    left: var(--left);
    top: 60px;
  }

  80%, 90% {
    left: var(--left);
    top: var(--top);
    transform: scale(.05);
  }

  100% {
    left: var(--left);
    top: var(--top);
    transform: scale(.05);
  }
}

.item-title-qty {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
}

.item-qty, .cart-item-stock-qty {
  color: #969696;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
}

.item-title {
  color: inherit;
  text-transform: uppercase;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 600;
}

.popup-content, .popup-camera {
  width: 60rem;
  height: 100%;
  z-index: 50;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  padding: 5rem 6rem;
  transition: all .5s;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  box-shadow: 0 4rem 6rem #00000040;
}

.popup-content svg, .popup-camera svg {
  height: 3rem;
  width: 3rem;
  fill: #3863be;
  vertical-align: middle;
  margin-right: .7rem;
  display: inline;
  transform: translateY(-1px);
}

@media only screen and (max-width: 61.25em) {
  .popup-content, .popup-camera {
    width: auto;
  }
}

@media only screen and (max-width: 31.25em) {
  .popup-content, .popup-camera {
    width: 100%;
    height: 90%;
  }
}

.popup-camera-video {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}

.popup-item-pic {
  height: 600px;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
}

.popup-item-pic::-webkit-scrollbar {
  width: 2px;
}

.popup-item-pic::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.popup-item-pic::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.popup-item-img {
  aspect-ratio: 1 / 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  transition: all .4s;
}

.section {
  height: 10px;
}

.popup-item-title {
  color: inherit;
  text-transform: uppercase;
  text-align: left;
  margin-top: 1.6rem;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 800;
}

.popup-item-description {
  color: inherit;
  text-align: left;
  text-transform: none;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
}

.item-price-area, .cart-price-area, .popup-item-price-area {
  display: flex;
}

.item-discount, .popup-item-discount {
  color: #fff;
  background-color: #000;
  display: flex;
}

.cart-discount {
  color: #fff;
  background-color: #000;
}

.item-sellingprice {
  color: inherit;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
}

.item-discountsellingprice, .popup-item-discountsellingprice {
  color: inherit;
  margin-right: .8rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
}

.item-originalprice, .cart-origprice, .popup-item-originalprice {
  color: inherit;
  margin-right: .5rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  text-decoration: line-through;
}

.popup-item-price {
  color: inherit;
  text-align: left;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
}

.popup-item-code {
  color: inherit;
  text-align: left;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  font-family: inherit;
  font-size: 1.4rem;
  font-weight: 500;
}

.add-cart {
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  z-index: 10;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  right: 0;
}

.add-cart svg {
  height: 2.2rem;
  width: 2.2rem;
  fill: #3863be;
}

.add-cart svg:hover {
  fill: #0096ff;
  transition: all .5s;
  transform: translateY(-2px);
}

.cart-count {
  color: #fff;
  text-align: center;
  height: 2rem;
  width: 2rem;
  z-index: 5;
  background-color: #ce1717;
  border-radius: 50%;
  padding: .5px;
  font-family: inherit;
  font-size: 1.2rem;
  line-height: 2rem;
  display: none;
  position: absolute;
  top: .5px;
  right: 12px;
}

.cart {
  width: 450px;
  height: 90vh;
  z-index: 5;
  background-color: #fff;
  padding: 20px;
  transition: all 1s;
  position: fixed;
  top: 80px;
  right: -100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 1px 4px #2825251a;
}

.cart-active {
  transition: all .5s;
  right: 0;
}

.cart-box {
  border-bottom: 1px solid #0000001a;
  grid-template-columns: 28% 58% 16%;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 10px;
  display: grid;
}

.cart-checkoutbox {
  border-bottom: 1px solid #0000001a;
  grid-template-columns: 28% 70%;
  align-items: center;
  margin-top: 1rem;
  padding-bottom: 10px;
  display: grid;
}

.cart-top {
  width: 100%;
  border-bottom: 1px solid #0000001a;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: inline-flex;
}

.cart-title {
  font-size: 1.8rem;
  font-weight: 800;
}

.cart-camera {
  border-color: #f2efee;
  border-radius: 0;
}

.cart-img, .cart-checkout-img {
  width: 75px;
  height: 98px;
  object-fit: cover;
}

.cart-detail-box {
  row-gap: .5rem;
  display: grid;
}

.cart-price-box, .cart-checkout-price-box {
  justify-content: space-between;
  display: flex;
}

.cart-item-attribute {
  display: inline-flex;
}

.cart-item-title, .cart-item-size, .cart-item-color, .cart-item-separator, .cart-checkout-item-title, .cart-checkout-item-size {
  text-transform: uppercase;
  color: inherit;
  font-size: 1.3rem;
}

.cartcheckout-orderno {
  color: inherit;
  padding-left: 6px;
  font-size: 1.3rem;
}

.cartcheckout-custname {
  text-transform: uppercase;
  color: inherit;
  border-bottom: 1px solid #0000002e;
  padding-bottom: 16px;
  font-size: 1.3rem;
}

.cartcheckout-orderno-row {
  display: inline-flex;
}

.cart-item-size, .cart-item-color, .cart-item-separator {
  margin-right: .5rem;
}

.cart-price, .cart-amt, .cart-checkout-amt, .cart-checkout-quantity {
  font-size: 1.4rem;
  font-weight: 500;
}

.cart-price {
  display: flex;
}

.delete-cart {
  cursor: pointer;
}

.delete-cart svg {
  height: 2.5rem;
  width: 2.5rem;
  fill: #3863be;
}

.delete-cart svg:hover {
  fill: #0096ff;
  transition: all .5s;
  transform: translateY(-2px);
}

.cartcheckout-total, .cart-total {
  border-bottom: 1px solid #0000001a;
  grid-template-columns: 58% 28%;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding-bottom: 10px;
  display: grid;
}

.cart-total-title-col, .cart-total-price-col, .cartcheckout-total-title-col, .cartcheckout-total-price-col {
  text-align: end;
}

.cart-subtotal-title, .cart-tax-title, .cart-total-title, .cartcheckout-ordertotal-title, .cartcheckout-tax-title, .cartcheckout-grandtotal-title {
  margin-top: .8rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.cart-subtotal-price, .cart-tax-price, .cart-total-price, .cartcheckout-ordertotal-price, .cartcheckout-tax-price, .cartcheckout-grandtotal-price {
  margin-top: .8rem;
  font-size: 1.5rem;
}

.btn--close-modal-cart {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 3.5rem;
}

.input-number-group input[type="number"]::-webkit-inner-spin-button, .input-number-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number-group input[type="button"] {
  min-width: 38px;
  width: auto;
  cursor: pointer;
  background-color: #eee;
  transition: all .3s;
}

.input-number-group .quantity-field {
  width: 5.3rem;
  height: 3.4rem;
  color: inherit;
  text-align: center;
  padding: .8rem 1rem;
  font-family: inherit;
  font-size: 1.3rem;
  transition: all .2s;
  position: relative;
  left: -6px;
  box-shadow: 0 2px 5px #d5d9d980;
}

.input-number-group .button-minus, .input-number-group .button-plus {
  height: 3.4rem;
  width: 3.4rem;
  padding: 0;
  font-weight: bold;
  position: relative;
  box-shadow: 0 2px 5px #d5d9d980;
}

.input-number-group .button-plus {
  border-radius: 0 .8rem .8rem 0;
  left: -9.1px;
}

.input-number-group .button-minus {
  border-radius: .8rem 0 0 .8rem;
}

/*# sourceMappingURL=index.d6439485.css.map */
